import React from 'react';

import { Link } from '@constellation/core';
import { AppContent } from 'content/AppContent';

const makingOverpayments: AppContent['makingOverpayments'] = {
  pageHeading: 'Making overpayments',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: `How to make an overpayment to your mortgage`,
        sectionBody: [
          {
            text: `You can overpay your mortgage by 10% per calendar year without having to pay any early repayment charges (ERCs). We'll use your balance as of 01 January of the current calendar year to work this out.`,
          },
          {
            text: `If you've already made any one-off overpayments this calendar year or have any regular overpayments set up but not yet taken, you'll need to take these off your 10% allowance.`,
          },
        ],
      },
      {
        sectionHeading: `Pay by bank transfer`,
        sectionBody: [
          {
            boldText: `You can make one-off and regular overpayments by bank transfer using these details`,
          },
          {
            list: [
              `Sort code: 30-00-00`,
              `Account number: 00332275`,
              `Account name: Lloyds Bank plc`,
              `Reference: your 14-digit mortgage account number followed by '00'. `,
            ],
          },
          {
            text: `If you want to overpay a specific sub-account on your mortgage, replace '00' with the last two digits of the sub-account's number.`,
          },
        ],
      },
      {
        sectionHeading: `Pay over the phone`,
        sectionBody: [
          {
            text: `To make an overpayment by phone, call our automated phone line on 0345 300 2627. The most you can overpay by phone in one go is £60,000, but you can make more than one payment per day.`,
          },
          {
            list: [
              `Please check your payment limits with your bank before making any large payments.`,
              `Use your 14-digit mortgage account number followed by '00' as the reference when making the payment.`,
            ],
          },
          {
            text: `If you want to overpay a specific sub-account on your mortgage, replace '00' with the last two digits of the sub-account's number. We can only take debit card payments from customers named on the mortgage account. We don't accept credit cards or debit Mastercards (cards beginning with a 5).`,
          }
        ],
      },
      {
        sectionHeading: 'Paying off your mortgage in full',
        sectionBody: [
          {
            text: "You'll need to request a redemption statement before you can pay your mortgage off in full. This statement will show you the total amount you need to pay. It'll include any recent payments, interest charges, ERCs, and any other charges that might apply."
          },
          {
            text: <>To request a redemption statement, please call us on <Link href='tel:03453002627'>0345 300 2627</Link>. We'd recommend not making any payments before the date on the statement, so you don't have any other charges added. </>
          }
        ]
      }
    ],
  },

  payByCheque: {
    label: 'Pay by cheque',
    firstParagraph: "Please make all cheques payable to ' Birmingham Midshires'.",
    secondParagraph: "On the back, write your 14-digit mortgage account number plus an extra '00' on the end, so it's 16-digits in total. To make an overpayment to a sub-account, replace '00' with the two-digit sub-account number instead. For example, '02'.",
    postToBoldText: 'Post to:',
    address: {
      businessName: 'Birmingham Midshires Mortgage Servicing',
      poBox: 'PO Box 833',
      city: 'Leeds',
      postcode: 'LS1 9PU'
    },
    thirdParagraph: 'Your payment will be applied on the date we receive the cheque and will usually show on your mortgage account the following day.'
  },
  internationalPayments: {
    label: 'International payments',

    ibanLabel: 'IBAN No: ',
    ibanNumber: 'GB19 LOYD 3015 9900 5800 06',

    firstParagraph: "Use your 14-digit mortgage account number followed by '00' as the reference when making the payment.",
    secondParagraph: "If you want to overpay a specific sub-account on your mortgage, replace '00' with the last two digits of the sub-account's number."
  }
};

export default makingOverpayments;
