const isValidInterestRate = (rate: number) => {
  if (undefined || rate <= 0 || rate > 15) {
    return false;
  }

  if (isNaN(rate)) {
    return false;
  }

  return true;
};

export default isValidInterestRate;
