import { useEffect } from "react";

import { useLocation } from "@interstellar/react-app-routing";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [pathname]);

  return null;
}

export default ScrollToTop;