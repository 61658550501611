import React from 'react';

import { Footer, FooterItem, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { AppLayoutContent } from '../appLayout/AppLayout.config';

function AppFooter(): React.JSX.Element {
  const { footer: { links, legalText } } = useContent<AppLayoutContent>();
  return (
    <Footer>
      {links &&
        links.map((link) => (
          <FooterItem
            key={link.label}
            data-testid={`footer_link_${link.label}`}
          >
            <StyledLink to={link.Url}>
              {link.label}
            </StyledLink>
          </FooterItem>
        ))}

      <Paragraph marginTop='05' marginBottom='none'>{legalText}</Paragraph>
    </Footer>
  );
}

export default AppFooter;
