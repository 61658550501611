/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FormEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  LabeledField,
  dataAndRest,
  specificDataAndRest,
} from '@constellation/core';
import { Minus, Plus } from '@constellation/core/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import classnames from 'classnames';

import NumberFieldProps from './NumberField.types';
import StyledNumberFieldWrapper, {
  StyledNumberFieldButton,
  StyledNumberFieldInput,
} from './styled';

const parseNumber = (value: any) => {
  if (!value || isNaN(parseFloat(value))) {
    return undefined;
  }
  const number = parseFloat(value);
  return number;
};

const NumberField = forwardRef<any, NumberFieldProps>(
  (
    {
      clickableAttribute,
      disabled = false,
      error,
      id,
      inputClassName,
      inputRef,
      isAssertiveError,
      label,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      max: newMax = 100,
      min: newMin = -15,
      name,
      onChange,
      onDecrement,
      onIncrement,
      optional = false,
      step = 0.25,
      supportiveText,
      tooltip,
      valid,
      value: newValue,
      ...rest
    },
    ref,
  ) => {
    const isInitialValue = useRef(true);
    const [max, setMax] = useState<typeof newMax>(newMax);
    const [min, setMin] = useState<typeof newMin>(newMin);
    const [value, setValue] = useState(newValue);
    useEffect(() => {
      if (isInitialValue.current) {
        isInitialValue.current = false;
      } else {
        if (typeof onChange === 'function') onChange(value);
      }
    }, [value]);

    useEffect(() => {
      if (parseNumber(max) !== parseNumber(newMax)) {
        setMax(newMax);
      }

      if (parseNumber(min) !== parseNumber(newMin)) {
        setMin(newMin);
      }

      if (parseNumber(value) !== parseNumber(newValue)) {
        setValue(newValue);
      }
    }, [newMax, newMin, newValue]);

    const minDisabled = () => parseNumber(value) <= min;
    const maxDisabled = () => parseNumber(value) >= max;

    const handleChange = (event: FormEvent<HTMLInputElement>) => {
      const {
        min: targetMin = newMin,
        max: targetMax = newMax,
        value: targetValue = '',
      } = event.currentTarget || {};
      if (
        targetValue !== '-' &&
        (targetValue === undefined ||
          parseNumber(targetValue) > parseNumber(targetMax) ||
          parseNumber(targetValue) < parseNumber(targetMin))
      ) {
        return;
      }

      setMax(parseNumber(targetMax) || 0);
      setMin(parseNumber(targetMin) || 0);
      setValue(parseNumber(targetValue));
    };

    const decrement = () => {
      if (min < value) {
        const newVal = Math.max(min, (parseNumber(value) || 0) - step);
        setValue(newVal.toFixed(2));
        if (typeof onDecrement === 'function') onDecrement(newVal);
      }
    };

    const increment = () => {
      if (max > value) {
        const newVal = Math.min(max, (parseNumber(value) || 0) + step);

        setValue(newVal.toFixed(2));
        if (typeof onIncrement === 'function') onIncrement(newVal);
      }
    };

    const [dataAttributes, inputAttributes] = dataAndRest(rest);
    const [clickableAttributes] = specificDataAndRest(rest, clickableAttribute);

    const ariaLabel = !label ? '' : `${label},`;

    const inputClassNames = classnames(
      'csl-number-field__input',
      inputClassName,
      { 'csl-number-field__input--valid': !disabled && valid },
    );

    const buttonUpClassName = classnames(
      'csl-number-field__button',
      'csl-number-field__button--up',
      {
        'csl-number-field__button--disabled': maxDisabled(),
      },
    );

    const buttonDownClassName = classnames(
      'csl-number-field__button',
      'csl-number-field__button--down',
      {
        'csl-number-field__button--disabled': minDisabled(),
      },
    );

    return (
      <LabeledField
        {...dataAttributes}
        createChildProps={false}
        error={error}
        isAssertiveError={isAssertiveError}
        label={label}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        name={name}
        optional={optional}
        ref={ref}
        supportiveText={supportiveText}
        tooltip={tooltip}
      >
        <StyledNumberFieldWrapper className="csl-number-field-wrapper">
          <StyledNumberFieldButton
            $disabled={minDisabled() || disabled}
            $down
            $error={Boolean(error)}
            $valid={!disabled && valid}
            className={buttonDownClassName}
            disabled={minDisabled() || disabled}
            onClick={decrement}
            type="button"
            tabIndex={0}
            aria-describedby={id}
            aria-label={
              minDisabled()
                ? `${ariaLabel} the minus button is disabled`
                : `${ariaLabel} minus`
            }
          >
            <Minus color="inherit" size="s3" trim />
          </StyledNumberFieldButton>
          <StyledNumberFieldInput
            $valid={!disabled && valid}
            {...inputAttributes}
            {...clickableAttributes}
            aria-atomic="true"
            aria-describedby={`${name}-error`}
            aria-invalid={Boolean(error)}
            aria-live="polite"
            className={inputClassNames}
            disabled={disabled}
            id={name}
            max={max}
            min={min}
            name={name}
            onChange={handleChange}
            ref={inputRef}
            required={!optional}
            type="number"
            placeholder="0"
            value={value}
          />
          <StyledNumberFieldButton
            $disabled={maxDisabled() || disabled}
            $error={Boolean(error)}
            $up
            $valid={!disabled && valid}
            disabled={maxDisabled() || disabled}
            className={buttonUpClassName}
            onClick={increment}
            type="button"
            tabIndex={0}
            aria-describedby={id}
            aria-label={
              maxDisabled()
                ? `${ariaLabel} the plus button is disabled`
                : `${ariaLabel} plus`
            }
          >
            <Plus color="inherit" size="s3" trim />
          </StyledNumberFieldButton>
        </StyledNumberFieldWrapper>
      </LabeledField>
    );
  },
);

NumberField.displayName = 'NumberField';

export default NumberField;
