import React from 'react';

import { Link, Strong } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { ItalicText } from 'client/routes/privacy/components/PrivacyPage.Styled';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';

const privacy: AppContent['privacy'] = {
  pageHeading: 'Privacy notice',
  firstInfoPanel: {
    contentSections: [
      {
        sectionHeading: 'How we use your personal information',
        sectionBody: [
          {
            text: 'This privacy notice explains how companies within Lloyds Banking Group use and look after your personal information. This includes what you tell us about yourself, what we learn by having you as a customer, and your marketing choices. This notice also tells you about your privacy rights and how the law protects you.',
          },
          {
            text: 'This privacy notice will apply to you if you are or have been a customer of Lloyds Banking Group. If your personal information has been provided to Lloyds Banking Group, but you are a not a customer, it may also apply to you. For example, if you have applied for a product or raised a complaint with us.',
          },
          {
            text: 'There are a range of different products and services throughout Lloyds Banking Group. Each one may have different requirements for the collection and use of your personal information. For this reason, some sections of this notice do not apply to all Lloyds Banking Group products and services.',
          },
        ],
      },
      {
        sectionHeading: 'Our privacy promise',
        sectionBody: [
          {
            text: 'We promise:',
          },
          {
            list: [
              'To keep your personal information safe and private.',
              'Not to sell your personal information.',
              'To give you ways to manage and review your marketing choices at any time.',
            ],
          },
          {
            subheading: 'Personal information and the law',
          },
          {
            text: 'This section tells you who we are, what your personal information is, and how we get it. It explains how the law protects you by controlling what is allowed to happen to it.',
          },
          {
            list: [
              <Link href="#who-we-are">Who we are</Link>,
              <Link href="#how-the-law-protects-you">
                How the law protects you
              </Link>,
              <Link href="#groups-of-personal-info">
                Groups of personal information
              </Link>,
              <Link href="#where-we-collect-information-from">
                Where we collect personal information from
              </Link>,
              <Link href="#how-long-we-keep-information">
                How long we keep your personal information
              </Link>,
              <Link href="#if-you-choose-not-to-give-information">
                If you choose not to give personal information
              </Link>,
              <Link href="#cookies-and-similar-tracking-tech">
                Cookies and similar tracking technologies
              </Link>,
            ],
          },
          {
            subheading: 'How personal information is used',
          },
          {
            text: "This tells you who we share personal information with. It explains what it's used for in marketing, to provide credit and to combat fraud - and how this can affect you.",
          },
          {
            list: [
              <Link href="#who-we-share-with">
                Who we share your personal information with
              </Link>,
              <Link href="#how-we-work-out-marketing">
                How we work out what marketing you receive
              </Link>,
              <Link href="#how-we-use-your-information">
                How we use your personal information to make automated decisions
              </Link>,
              <Link href="#credit-reference-agencies">
                Credit Reference Agencies (CRAs)
              </Link>,
              <Link href="#fraud-prevention-agencies">
                Fraud Prevention Agencies (FPAs)
              </Link>,
              <Link href="#sending-data-outside-uk">
                Sending data outside of the UK, Isle of Man, Channel Islands and
                EEA
              </Link>,
            ],
          },

          {
            subheading: 'The control you have',
          },
          {
            text: 'This section explains your data privacy rights and how you can action them. It also covers how to contact us or the data privacy regulator, or how to make a complaint.',
          },
          {
            list: [
              <Link href="#your-data-privacy-rights">
                Your data privacy rights
              </Link>,
              <Link href="#how-to-contact-us">How to contact us</Link>,
            ],
          },
        ],
      },
      {
        sectionHeading: 'Personal information and the law',
        sectionID: 'who-we-are',
        sectionBody: [
          {
            subheading: 'Who we are',
          },
          {
            boldText:
              "This section gives you the legal name of the company that holds your personal information. This is known as the 'legal entity'. It also tells you how you can get in touch with us.",
          },
          {
            text: (
              <>
                Your personal information is held by Birmingham Midshires which
                is part of Lloyds Banking Group. Lloyds Banking Group is made up
                of a mix of companies, set up on different legal entities. We'll
                let you know which you have a relationship with, when you take
                out a product or service with us. You can find out more about us
                at{' '}
                <Link
                  external
                  href="https://www.lloydsbankinggroup.com/who-we-are/our-brands.html"
                >
                  https://www.lloydsbankinggroup.com/who-we-are/our-brands.html
                </Link>
              </>
            ),
          },
          {
            subheading: 'Contacting us about data privacy',
          },
          {
            text: (
              <>
                Please see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to contact us about any of the topics set out in this
                Privacy notice.
              </>
            ),
          },
        ],
      },

      {
        sectionHeading: 'How the law protects you',
        sectionID: 'how-the-law-protects-you',
        sectionBody: [
          {
            boldText:
              'This section sets out the legal reasons we rely on, for each of the ways we may use your personal information.',
          },
          {
            text: 'As well as our Privacy Promise, your privacy is protected by law. This section explains how that works.',
          },
          {
            text: 'Data Protection law says that we can use personal information only if we have a proper reason to do so. This includes sharing it outside Lloyds Banking Group. The law says we must have one or more of these reasons:',
          },
          {
            list: [
              'To fulfil a contract we have with you, or',
              'When it is our legal duty, or',
              'When it is in our legitimate interest, or',
              'When you consent to it, or',
              'When it is in the public interest.',
            ],
          },
          {
            text: "When we have a business or commercial reason of our own to use your personal information, this is called a 'legitimate interest'. We will tell you what that is, if we are going to rely on it as the reason for using your personal information. Even then, it must not unfairly go against your interests.",
          },
          {
            text: (
              <>
                You have the right to object to use of your personal information
                in this way. You can do this by telling us anything that we may
                need to consider, to understand if our use of your personal
                information is fair. Please see the{' '}
                <Link href="#your-data-privacy-rights">
                  'Your data privacy rights'
                </Link>{' '}
                section for more information.
              </>
            ),
          },
          {
            text: "The law and other regulations treat some types of sensitive personal information as special. This personal information is called 'special categories of personal information. We will not collect or use these types of personal information without your consent unless the law allows us to do so. If we do, it will only be when it is necessary:",
          },
          {
            list: [
              'For reasons of substantial public interest, or',
              'To establish, exercise or defend legal claims.',
            ],
          },
          {
            text: 'Here is a list of all the ways that we may use your personal information, and which of the reasons we rely on to do so. This is also where we tell you what our legitimate interests are where it is the reason for using your personal information.',
          },
          {
            text: "We may rely on different reasons for using the same personal information, as this depends on the situation. For example, we may use your personal information to make an automated decision about you. This could be because of a legal duty, or to fulfil a contract we've made with you.",
          },
        ],
      },
    ],
  },

  personalInformationTable: {
    headers: [
      'Our reasons for using your personal information',
      'What we use your personal information for',
      'Our legitimate interests',
    ],

    tableEntries: [
      {
        heading: 'Serving you as a customer',
        column1: [
          'Your consent.',
          'Fulfilling contracts.',
          'Our legitimate interests.',
          'Our legal duty.',
        ],
        column2: [
          'To manage our relationship with you or your business.',
          'To study how our customers use products and services from us and other organisations.',
          'To communicate with you about our products and services.',
          'To develop and manage our brands, products and services.',
          'To develop and carry out marketing activities.',
          'To make automated decisions about you or your products.',
          'Managing competitions and promotions.',
        ],
        column3: [
          'Developing products and services, and what we charge for them.',
          'Defining types of customers for new products or services.',
          'Seeking your consent when we need it to contact you.',
          'Being efficient about how we fulfil our legal and contractual duties.',
        ],
      },
      {
        heading: 'Business improvement',

        column1: [
          'Fulfilling contracts.',
          'Our legitimate interests.',
          'Our legal duty.',
        ],
        column2: [
          'To test new products.',
          'To manage how we work with other companies that provide services to us and our customers.',
          "To develop new ways to meet our customers' needs and to grow our business.",
        ],
        column3: [
          'Developing products and services, and what we charge for them.',
          'Defining types of customers for new products or services.',
          'Being efficient about how we fulfil our legal and contractual duties.',
        ],
      },
      {
        heading: 'Managing our operations',

        column1: [
          'Fulfilling contracts.',
          'Our legitimate interests.',
          'Our legal duty.',
        ],
        column2: [
          'To deliver our products and services.',
          'To make and manage customer payments.',
          'To manage fees, charges and interest due on customer accounts.',
          'To collect and recover money that is owed to us.',
          'To manage and provide treasury and investment products and services.',
          'To archive for historical purposes.',
          'To analyse our environmental impact and information about climate.',
        ],
        column3: [
          'Being efficient about how we fulfil our legal and contractual duties.',
          'Complying with rules and guidance from regulators.',
        ],
      },
      {
        heading: 'Managing security, risk and crime prevention',
        column1: [
          'Fulfilling contracts.',
          'Our legitimate interests.',
          'Our legal duty.',
        ],
        column2: [
          'To detect, investigate, report, and seek to prevent financial crime.',
          'To manage risk for us and our customers.',
          'To obey laws and regulations that apply to us.',
          'To respond to complaints and seek to resolve them.',
        ],
        column3: [
          'Developing and improving how we deal with financial crime and doing our legal duties in this respect.',
          'Complying with rules and guidance from regulators.',
          'Being efficient about how we fulfil our legal and contractual duties.',
        ],
      },
      {
        heading: 'Business management',
        column1: [
          'Our legitimate interests',
          'Our legal duty',
          'Fulfilling contracts',
        ],
        column2: [
          'To run our business in an efficient and proper way. This includes managing our financial position, business capability, planning, adding and testing systems and processes, managing communications, corporate governance, and audit.',
          'To exercise our rights set out in agreements or contracts',
        ],
        column3: [
          'Complying with rules and guidance from regulators.',
          'Being efficient about how we fulfil our legal and contractual duties.',
        ],
      },
    ],
  },
  processingSpecialCategoriesTable: {
    header: 'For processing special categories of personal information',
    column1: [
      'Substantial public interest',
      'Responding to regulatory requirements',
      'Legal claims',
      'Consent',
    ],
    column2: [
      [
        'Using criminal records data to help prevent, detect, and prosecute unlawful acts and fraudulent behaviour.',
        'Using criminal and health information to provide insurance products.',
        'Using your data to understand, test systems and respond to your support needs.',
      ],
      [
        'Showing whether we have assessed your situation in the right way.',
        'Passing information to the regulator so they can investigate whether we have acted in the right way.',
      ],
      [
        'Using any special categories of personal information as needed to establish, exercise or defend legal claims.',
      ],
      [
        'Telling you that we need your consent to process special categories of personal information, when that is what we rely on for doing so.',
      ],
    ],
  },

  groupsOfPersonalInfo: {
    heading: {
      id: 'groups-of-personal-info',
      text: 'Groups of personal information',
    },
    subheading:
      'This explains the meaning of the different types of personal information that data protection law covers.',
    paragraph:
      "We use many different kinds of personal information for a range of reasons, including managing our relationship with you and the products you hold. They are grouped together and listed below. We may use all or only some of these groups depending on the relationship you have with us. The groups are all listed here so that you can see what we may know about you, even if we simply get it from seeing which shops you buy things in. We don't use all this data in the same way. Some of it is useful for marketing, or for providing services to you. But some of it is private and sensitive and we treat it that way.",
  },

  groupsOfPersonalInfoTable: {
    headers: ['Type of personal information', 'Description'],
    upperColumn1: [
      'Financial',
      'Contact',
      'Socio-Demographic',
      'Transactional',
      'Contractual',
      'Locational',
      'Behavioural',
      'Technical',
      'Communications',
      'Social Relationships',
      'Open Data and Public Records',
      'Usage Data',
      'Documentary Data',
      'Gender identity',
    ],
    upperColumn2: [
      'Your financial position, status and history.',
      'Your name, where you live and how to contact you.',
      'This includes details about your work or profession, nationality, education and where you fit into general social or income groupings.',
      'Details about payments to and from your accounts and insurance claims you make.',
      'Details about the products or services we provide to you.',
      'Data we get about where you are. This may come from your mobile phone or the place where you connect a computer to the internet. It can also include shops where you buy something with your card.',
      'Details about how you use products and services from us and other organisations.',
      '	Details on the devices and technology you use, including your Internet Protocol (IP) address.',
      'What we learn about you from letters and emails you write to us, and conversations between us.',
      'Your family, friends, and other relationships.',
      'Details about you that are in public records such as the Electoral Register, and information about you that is openly available on the internet.',
      'Other data about how you use your products and services.',
      'Details about you that are stored in documents in different formats, or copies of them. This could include things like your passport, driving licence, or birth certificate.',
      'Information about the gender that you may identify as.',
    ],
    specialCategoriesType: 'Special categories of personal information',
    specialCategoriesFirstLine:
      'The law and other regulations treat some types of personal information as special. We will only collect and use these if the law allows us to do so:',
    specialCategoriesList: [
      'Racial or ethnic origin',
      'Religious, political or philosophical beliefs',
      'Trade union membership',
      'Genetic and bio-metric data',
      'Health data',
      'Information that could identify aspects of your sex life',
      'Sexual orientation',
      'Criminal records of convictions and offences',
      'Allegations of criminal offences.',
    ],
    specialCategoriesSecondLine:
      "You can read how we may use special types of data in the table 'How the law protects you'.",
    lowerColumn1: ['Choices', 'National Identifier'],
    lowerColumn2: [
      'Any permissions, consents, or preferences that you give us. This includes things like how you want us to contact you, or if you prefer large-print formats.',
      'A number or code given to you by a government to identify who you are. This may be a National Insurance or social security number, or Tax Identification Number (TIN).',
    ],
  },

  secondInfoPanel: {
    contentSections: [
      {
        sectionHeading: 'Where we collect personal information from',
        sectionID: 'where-we-collect-information-from',
        sectionBody: [
          {
            subheading:
              'This section lists all the places where we get data that counts as part of your personal information.',
          },
          {
            text: 'We may collect personal information about you from other Lloyds Banking Group companies and any of these sources:',
          },
          {
            subheading: 'Data you give to us',
          },
          {
            text: "This covers data that you give and data provided by people linked with you or your business's product or service, or people working on your behalf.",
          },
          {
            text: (
              <ItalicText>
                This could mean a joint account holder, trustee, or fellow
                company director.
              </ItalicText>
            ),
          },
          {
            list: [
              'When you apply for our products and services',
              'When you talk to us on the phone, face to face, or in branch, including recorded calls and notes we make',
              'When you use our websites, mobile device apps, web chat or SignVideo services',
              'In emails and letters',
              'In insurance claims or other documents',
              'In financial reviews and interviews',
              'In customer surveys',
              'If you take part in our competitions or promotions.',
            ],
          },
          {
            subheading: 'Data we collect when you use our services',
          },
          {
            text: 'This covers two things: details about how and where you access our services, and account activity that is shown on your statement.',
          },
          {
            list: ['Payment and transaction data'],
          },
          {
            text: (
              <ItalicText>
                This includes the amount, frequency, type, location, origin and
                recipients. If you borrow money, it also includes details of
                repayments and whether they are made on time and in full.
              </ItalicText>
            ),
          },
          {
            list: ['Profile and usage data'],
          },
          {
            text: (
              <ItalicText>
                This includes the security details you create and use to connect
                to our services. It also includes your settings and marketing
                choices. We also gather data from the devices you use (such as
                computers and mobile phones) to connect to our internet, mobile
                and telephone banking services. We also use cookies and similar
                tracking technologies to collect data. This can be from our
                websites or mobile apps and when you receive, open or respond to
                emails we send you. You can find out more about this in our{' '}
                <StyledLink to={routes.CookiesPolicy}>
                  Cookies Policy
                </StyledLink>
              </ItalicText>
            ),
          },
          {
            subheading: 'Data from outside organisations',
          },
          {
            list: [
              'Companies that introduce you to us. For example a price comparison website, a broker, or a store or car dealership offering finance deals through us.',
              'Financial advisers.',
              'Credit card providers like Visa and Mastercard.',
              'Credit reference agencies such as TransUnion, Equifax and Experian.',
              'Insurers.',
              'Retailers.',
              'Social networks and other technology providers. For instance, when you click on one of our ads.',
              'Fraud prevention agencies.',
              'Other financial services companies. This could be to fulfil a payment or other service as part of a contract with you, or to help prevent, detect and prosecute unlawful acts and fraudulent behaviour.',
              'Employers. For instance, to get a reference if you apply for a mortgage.',
              'Payroll service providers.',
              'Land agents, such as firms who do property valuations for mortgages.',
              'Public information sources such as the Electoral Register or Companies House, published media and social networks.',
              'Products that have a loyalty scheme, agents, suppliers, sub-contractors and advisers. These can be types of firms we use to help us run accounts and services. They can also be specialist companies who advise us on ways to develop and improve our business.',
              "Market researchers. These firms may get in touch with you on our behalf to ask you for your opinions and feedback. Sometimes these firms will combine what you tell them with data from other sources to study it. They'll use this to help us understand our customers' point of view, so we can improve the way we work as a business.",
              'Firms providing data services. We receive reports that we use to understand our customers better and look for general patterns and trends.',
              'Medical professionals. For some insurance products, we may ask your GP or other medical practitioner to send us a report. We will only do this if we get your consent first.',
              'Government and law enforcement agencies.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'How long we keep your personal information',
        sectionID: 'how-long-we-keep-information',
        sectionBody: [
          {
            subheading:
              'This section explains how long we may keep your information for and why.',
          },
          {
            text: 'We will keep your personal information for as long as you have a product or are using a service from us, and for up to 10 years after. The reasons we may do this are:',
          },
          {
            list: [
              'To respond to a question or complaint, or to show whether we gave you fair treatment.',
              'To study customer data as part of our own internal research.',
            ],
          },
          {
            text: "To obey rules that apply to us about keeping records. We may also keep your personal information for longer than 10 years if we archive it for historical purposes or if we can't delete it for legal, regulatory or technical reasons. For example, we have to hold pension transfer information indefinitely.",
          },
          {
            text: 'We will only use your personal information for those purposes and will make sure that your privacy is protected.',
          },
        ],
      },
      {
        sectionHeading: 'If you choose not to give personal information',
        sectionID: 'if-you-choose-not-to-give-information',
        sectionBody: [
          {
            subheading:
              'You can choose not to give us personal information. In this section we explain the effects this may have.',
          },
          {
            text: 'We may need to collect personal information by law, or to enter into or fulfil a contract we have with you.',
          },
          {
            text: "If you choose not to give us this personal information, it may delay or prevent us from fulfilling our contract with you, or doing what we must do by law. It may also mean that we can't run your products or services. It could mean we cancel a product or service you have with us.",
          },
          {
            text: "We sometimes ask for personal information that is useful, but not required by law or a contract. We will make this clear when we ask for it. You don't have to give us these extra details and it won't affect the products or services you have with us.",
          },
        ],
      },
      {
        sectionHeading: 'Cookies and similar tracking technologies',
        sectionID: 'cookies-and-similar-tracking-tech',
        sectionBody: [
          {
            subheading:
              'This section explains how we use data on our websites, apps and emails',
          },
          {
            subheading:
              'We may use cookies and similar tracking technologies on our websites and apps, and in emails we send you.',
          },
          {
            subheading: 'Cookies',
          },
          {
            text: 'Cookies are small computer files that get sent to your computer, tablet or mobile device by websites when you visit them. They stay on your device and get sent back to the website they came from, when you go there again. Cookies store information about your visits to that website, such as your choices and other details. Some of this data does not contain personal details about you or your business, but it is still protected by this Privacy notice.',
          },
          {
            subheading: 'Email tracking',
          },
          {
            text: 'We track emails to help us improve the communications we send. Small images called pixels within our emails to tell us things like whether you opened the email, how many times and the device you used. We may also set a cookie to find out if you clicked any links in the email.',
          },
          {
            text: (
              <Strong>
                To find out more about how we use cookies and email tracking,
                please see our{' '}
                <StyledLink to={routes.CookiesPolicy}>
                  Cookies Policy
                </StyledLink>
                .
              </Strong>
            ),
          },
        ],
      },
      {
        sectionHeading: 'How personal information is used',
        sectionID: 'who-we-share-with',
        sectionBody: [
          {
            subheading: 'Who we share your personal information with',
          },
          {
            boldText:
              'We may share your personal information with outside organisations such as credit card providers, insurers or tax authorities. This is so that we can provide you with products and services, run our business, and obey rules that apply to us. Here we list all the types of organisation that we may share your personal information with.',
          },
          {
            subheading: 'Lloyds Banking Group',
          },
          {
            text: 'We may share your personal information with other companies in Lloyds Banking Group.',
          },
          {
            subheading: 'Authorities',
          },
          {
            text: 'This means official bodies that include:',
          },
          {
            list: [
              'Central and local government',
              'HM Revenue & Customs, regulators and other tax authorities',
              'UK Financial Services Compensation Scheme and other deposit guarantee schemes',
              'Law enforcement and fraud prevention agencies.',
            ],
          },

          {
            subheading: 'Banking and financial services',
          },
          {
            text: 'Outside companies we work with to provide services to you and to run our business.',
          },
        ],
      },
    ],
  },
  bankingAndFinancialServicesList: {
    listItems: [
      'Agents, suppliers, sub-contractors and advisers.',
      'Credit reference agencies such as TransUnion, Equifax and Experian',
      "Someone linked with you or your business's product or service.",
      'Other financial services companies.',
      'Independent Financial Advisors',
      'Price comparison websites and similar companies.',
      'Employers (for instance, to confirm your identity if we ask for a mortgage reference)',
      'Companies you ask us to share your data with.',
      'Companies whose shares we hold for you in one of our nominee companies.',
    ],

    italicItems: [
      'These are types of firm that we use to help us run accounts, policies and services.',
      'This could mean a joint account holder, trustee, or fellow company director.',
      'This can be to fulfil a payment or other service as part of a contract with you; or to help prevent, detect and prosecute unlawful acts and fraudulent behaviour',
      'This could be someone who you, your employer, or product owner have selected to advise on things like pensions or life assurance.',
      'We only do so if you buy something from us through such a company. We only share details needed to confirm what you have bought, to fulfil our own contract with the company.',
      'This is to do with something called Open Banking, which gives you more freedom and control to use your own banking data. It can make it easier for you or your business to shop around for products like credit cards, savings and current accounts.',
      'This is usually to do with Share Dealing accounts. This is where we hold shares for you in the name of our nominee company, and that company asks us to share details of the underlying shareholder with them.',
    ],
  },

  insurers: {
    heading: 'Insurers',
    firstParagraph:
      'We share personal information with insurance industry companies to process claims and help reduce fraud. We do that in these ways:',
    listItems: [
      'If you apply for insurance through us, we may pass your personal or business details to the insurer.',
      'If you apply for insurance with us as the insurer, we may share your personal or business details with reinsurers.',
      'If you make an insurance claim, information you give to us or the insurer may be put on a register of claims. This will be shared with other insurers, our agents, suppliers and sub-contractors. In some cases we may share it with our business partners, if you also have a relationship with them.',
    ],
  },

  otherServicesAndSchemes: {
    heading: 'Other services and schemes',
    firstParagraph:
      'These are organisations that we may need to share your personal information with, because of what you can do with the product or service you have with us.',
    listItem: (
      <>
        If you have a debit, credit or charge card with us, we will share
        transaction details with companies that help us to provide this service
        (such as Visa and Mastercard).
      </>
    ),

    italicListItem:
      'We need to do this to keep your account balance and statements up to date, for example.',
    listItems: [
      'If you use direct debits, we will share your data with the Direct Debit scheme.',
      'If you have a product that has a loyalty scheme, we will share your data with that scheme.',
      'If you have a product with benefits such as travel insurance or discount offers, we will share your data with the benefit providers. We may also share it with other companies involved in how you use the service such as a private car park operator.',
      'If you have a secured loan or mortgage with us, we may share information with other lenders who also hold a charge on the property, such as the other party in a shared ownership scheme.',
      'If you or your employer have a product with us that includes extra services or features, such as daily rental vehicles, we will share your data with the providers of those services or features.',
      'If a third party contacts us about fines, penalties or charges imposed on you, we may share this with your employer, or share your details with these third parties.',
      'If we carry out driving license, vehicle or any other checks as part of our products, services, or features we may share information with suppliers and other third parties needed for us to provide those to you, or your employer.',
    ],
  },

  generalBusiness: {
    heading: 'General business',
    firstParagraph:
      'Outside companies we use to provide our services, help grow and improve our business.',

    listItems: [
      'Companies we have a contractual arrangement, joint venture or agreement with',
      'Organisations that introduce you to us',
      'Market researchers',
      'Advisers who help us to come up with new ways of doing business.',
      'Advertisers and technology providers that you use such as websites you visit, social media , and providers of apps and smart devices.',
    ],

    italicItems: [
      'This could be a store, a manufacturer, or a broker who is offering finance deals or other products, services or features through us.',
      'This might be a store or car dealership that offers finance deals through us, or a price comparison website.',
      "These firms may get in touch with you on our behalf to ask you for your opinions and feedback. Sometimes these firms will combine what you tell them with data from other sources to study it. They'll use this to help us understand our customers' point of view, so we can improve the way we work as a business.",
      'This might be a legal firm, IT supplier or consultancy.',
    ],

    soThatHeading: 'That is so that:',
    soThatList: [
      'You are only shown marketing we think you might be interested in.',
      "You are not shown marketing we think you won't be interested in.",
      'We can try to find new customers who are like you, or have similar interests to yours.',
    ],

    learnMoreLink:
      'Learn more about how we use your information to make automated decisions',
  },

  outsideCompanies: {
    heading: 'Outside companies you may use',
    firstParagraph: 'This could include:',
    listItems: [
      'Price comparison websites and similar companies',
      'Third party payment providers',
    ],
    italicListItems: [
      'We only do so if you buy something from us through such a company. We only share details needed to confirm what you have bought, to fulfil our own contract with the company.',
      'For example if you use an App on your phone to pay for something, then we may share payment and transaction data with them. This includes the amount, type, location, origin and recipients.',
    ],
  },

  companyMergers: {
    heading: 'Company mergers, takeovers and transfers of products or services',
    firstParagraph:
      'We may also share your personal information if the ownership of products or services or the make-up of Lloyds Banking Group changes in the future:',
    italicItem:
      "This is sometimes called Mergers & Acquisitions or 'company takeovers'.",
    listItems: [
      'We may choose to sell, transfer, or merge parts of our business, or our assets, including products or services. Or we may try to bring other businesses into Lloyds Banking Group.',
      "During any such process, we may share your data with other parties involved. We'll only do this if they agree to keep your data safe and private.",
      'If the change to our Group happens, then other parties may use your data in the same way as set out in this notice.',
    ],
  },

  thirdInfoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            subheading: 'Sharing data that does not say who you are',
          },
          {
            text: "We may share or sell some data to other companies outside Lloyds Banking Group, but only when no-one's identity can be known or found out.",
          },
          {
            text: 'One of the ways we can do this is by grouping customers together.',
          },
          {
            text: 'We do this so we and those other companies can look for general patterns and trends in the data, while keeping the customer identities secure. For instance, we might look at customers in a specific location shopping for clothes. We could look at a group of customers by their age group, the area they live in, or their spending habits, such as the average amount spent in one month. But we would not include any data about who these customers are. When we use data this way, we use all the information - including current and historical data - that we hold about you and our other customers.',
          },
          {
            text: 'We and those other companies do this to learn about the types of customers we each have, how they use our products, and how our products perform for them. We and those other companies also learn about the markets we each operate in and how our competitors perform. The law says data is not considered to be personal information after customer identities have been securely hidden in this way.',
          },
        ],
      },
      {
        sectionHeading: 'How we work out what marketing you receive',
        sectionID: 'how-we-work-out-marketing',
        sectionBody: [
          {
            subheading:
              'We use marketing to let you know about products, services and offers that you may want. This section tells you how we decide what marketing to show or send you. It also explains how we work out what you may be interested in.',
          },
          {
            text: "We may use your personal information to make decisions about what products, services and offers we think you may be interested in. This is what we mean when we talk about 'marketing'.",
          },

          {
            subheading:
              'When we can use your personal information for marketing',
          },
          {
            text: "We can only use your personal information to send you marketing messages if we have either your consent or a 'legitimate interest'. That is when we have a business or commercial reason to use your personal information. It must not conflict unfairly with your own interests",
          },

          {
            subheading: 'How we decide what marketing may interest you.',
          },
          {
            text: 'The personal information we have for you is made up of what you tell us, and data we collect when you use our services, or from outside organisations we work with. We study this to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you.',
          },
          {
            text: 'This is called profiling for marketing purposes.',
          },
          {
            text: (
              <>
                You can contact us at any time and ask us to stop using your
                personal information this way. Please see the '
                <Link href="#your-data-privacy-rights">
                  Your data privacy rights
                </Link>
                ' section for more information, and the '
                <Link href="#how-to-contact-us">How to contact us</Link>'
                section to contact us about this.
              </>
            ),
          },

          {
            subheading: 'How we send you marketing',
          },
          {
            text: 'We may show or send you marketing material online (on our own and other websites including social media), in our own and other apps, or by email, mobile phone, post or through smart devices and other digital channels.',
          },
          {
            text: 'You can also tell us not to collect data while you are using our websites or mobile apps.',
          },
          {
            text: 'If you do, you may still see some marketing but it will not be tailored to you.',
          },
          {
            text: (
              <>
                See our{' '}
                <StyledLink to={routes.CookiesPolicy}>
                  Cookies Policy
                </StyledLink>{' '}
                for details about how we use this data to improve our websites
                and mobile apps.
              </>
            ),
          },

          {
            subheading: 'Your marketing choices',
          },
          {
            text: "You can tell us to stop sending you marketing at any time. Whatever you choose, we'll still send you statements and other important information such as changes to your existing products and services.",
          },
          {
            text: 'We do not sell the personal information we have about you to outside organisations.',
          },
          {
            text: "We may ask you to confirm or update your choices, if you take out any new products or services with us in future. We'll also ask you to do this if there are changes in the law, regulation, or the structure of our business.",
          },
          {
            text: (
              <>
                If you change your mind you can contact us to update your
                choices at any time. Please see the{' '}
                <Link href="#your-data-privacy-rights">
                  'Your data privacy rights'
                </Link>{' '}
                section for more information, and the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to contact us about this.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading:
          'How we use your information to make automated decisions',
        sectionID: 'how-we-use-your-information',
        sectionBody: [
          {
            subheading:
              'Here we tell you how we use automated systems to make decisions about you and your money. We also explain your right to challenge decisions made this way.',
          },
          {
            text: 'We sometimes use systems to make automated decisions about you or your business. This helps us to make sure our decisions are quick, fair, efficient and correct, based on what we know. Automated decisions can affect the products, services or features we may offer you now or in the future, or the price we charge for them. They are based on personal information that we have or that we are allowed to collect from others.',
          },
          {
            text: 'Here are the types of automated decision we make:',
          },

          {
            subheading: 'Pricing',
          },
          {
            text: 'We may decide what to charge for some products and services based on what we know. For instance, if you use our online mortgage calculator, it will use the personal financial details you put in to estimate the kind of mortgage we may offer you. If you apply for insurance, we will compare what you tell us with other records to work out how likely you are to make a claim. This will help us to decide whether to offer you the product and what price to charge you.',
          },

          {
            subheading: 'Tailoring products, services, offers and marketing',
          },
          {
            text: "The personal information we have for you is made up of what you tell us, and data we collect when you use our services, or from outside organisations we work with. We study this to form a view on what we think you may do, want, or need. This is how we decide which products, services and offers may be relevant for you. We use this information to place you in groups with similar customers. These are called customer segments. We use these to study and learn about our customers' needs, and to make decisions based on what we learn. This is called profiling. It helps us to design products, services and offers for different customer segments, and to manage our relationships with them. It also helps us tailor what individuals see on our own and other websites and mobile apps, including social media.",
          },
          {
            text: 'We also use customer segments from outside companies we work with such as social networks. We ask them for groups of people who are similar to you, or have interests that are like yours in some ways. These companies help us look for new customers who may be interested in products, services or offers that our existing customers are interested in.',
          },

          {
            subheading: 'Detecting fraud',
          },
          {
            text: 'We use your personal information to help decide if your personal or business accounts may be being used for fraud or money-laundering. We may detect that an account is being used in ways that fraudsters work. Or we may notice that an account is being used in a way that is unusual for you or your business. If we think there is a risk of fraud, we may stop activity on the accounts or refuse access to them.',
          },

          {
            subheading: 'Opening accounts',
          },
          {
            text: "When you open an account with us, we check that the product or service is relevant for you, based on what we know. We also check that you or your business meet the conditions needed to open the account. This may include checking age, residency, nationality or financial position. It may mean we can't offer you the account you want.",
          },

          {
            subheading: 'Approving credit',
          },
          {
            text: "We use a system to decide whether to lend money to you or your business, when you apply for credit such as a loan or credit card. This is called credit scoring. It uses past data to assess how you're likely to act while paying back any money you borrow. This includes data about similar accounts you may have had before.",
          },
          {
            text: 'Credit scoring uses data from three sources:',
          },
          {
            list: [
              'Your application form',
              'Credit reference agencies',
              'Data we may already hold.',
            ],
          },
          {
            text: 'It gives an overall assessment based on this. Banks and other lenders use this to help us make responsible lending decisions that are fair and informed.',
          },
          {
            text: 'Credit scoring methods are tested regularly to make sure they are fair and unbiased.',
          },
          {
            text: 'This credit score can determine whether we will give you credit, or allow you to take another product or service.',
          },

          {
            subheading: 'Your rights',
          },
          {
            text: 'You can object to an automated decision we have made, and ask that a person reviews it.',
          },
          {
            text: (
              <>
                If you want to know more about these rights, please see the{' '}
                <Link href="#your-data-privacy-rights">
                  'Your data privacy rights'
                </Link>{' '}
                section for more information, and the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to contact us about this.
              </>
            ),
          },
        ],
      },

      {
        sectionHeading: 'Credit Reference Agencies (CRAs)',
        sectionID: 'credit-reference-agencies',
        sectionBody: [
          {
            subheading:
              'This section explains how we work with outside companies to decide whether to lend you money through products like credit cards or loans. It also explains how we manage our products during your relationship with us. It explains what we do and why we do it.',
          },
          {
            text: 'We carry out credit and identity checks when you apply for a product or services for you or your business. We may use Credit Reference Agencies to help us with this.',
          },
          {
            text: 'We will go on sharing your personal information with CRAs for the duration of your relationship with us. From time to time, we may also search information that the CRAs have, to help us manage your accounts.',
          },
          {
            text: 'We will share your personal information with CRAs and they will give us information about you. The data we exchange can include:',
          },
          {
            list: [
              'Name, address and date of birth',
              'Credit application',
              'Details of any shared credit',
              'Financial situation and history',
              'Fraud prevention information',
            ],
          },
          {
            text: "We'll use this data to:",
          },
          {
            list: [
              'Assess whether you or your business is able to afford to make repayments',
              "Make sure what you've told us is true, correct and up to date",
              'Help detect and prevent financial crime',
              'Manage your accounts with us',
              'Trace and recover debts',
              'Make sure any offers are relevant for you',
            ],
          },
          {
            text: 'This will include details of money going into the account, and the account balance. If you borrow, it will also include details of your repayments and whether you repay in full and on time. We will also tell the CRAs when you settle your accounts with us. The CRAs may give this information to other organisations that want to check your credit status.',
          },
          {
            text: 'When we ask CRAs about you or your business, they will note it on your credit file. This is called a credit search. Other lenders may see this and we may see credit searches from other lenders.',
          },
          {
            text: "If you apply for a product with someone else, we will link your records with theirs. We'll do the same if you tell us you have a spouse, partner or civil partner - or that you are in business with other partners or directors.",
          },
          {
            text: "You should tell them about this before you apply for a product or service. It's important that they know your records will be linked together, and that credit searches may be made on them.",
          },
          {
            text: "CRAs will also link your records together. These links will stay on your files unless one of you asks the CRAs to break the link. You'll normally need to give proof that you no longer have a financial link with each other.",
          },
          {
            text: 'You can find out more about the CRAs on their websites, in the Credit Reference Agency Information Notice (CRAIN). This includes details about:',
          },
          {
            list: [
              'Who they are',
              'Their role as fraud prevention agencies',
              'The data they hold and how they use it',
              'How they share personal information',
              'How long they can keep data',
              'Your data protection rights.',
            ],
          },
          {
            text: 'Here are links to the information notice for each of the three main Credit Reference Agencies:',
          },
          {
            text: (
              <Link external href="https://www.transunion.co.uk/crain">
                TransUnion
              </Link>
            ),
          },
          {
            text: (
              <Link external href="https://www.equifax.co.uk/crain">
                Equifax
              </Link>
            ),
          },
          {
            text: (
              <Link external href="https://www.experian.co.uk/crain">
                Experian
              </Link>
            ),
          },
          {
            text: (
              <>
                We use these agencies mainly with UK, Isle of Man or EEA
                products and customers. For non-UK, Isle of Man or EEA products
                and customers, we may not use these agencies. We may instead use
                other regional CRAs. Please see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section if you'd like to speak to us about this.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Fraud Prevention Agencies (FPAs)',
        sectionID: 'fraud-prevention-agencies',
        sectionBody: [
          {
            subheading:
              'This section deals with information we share outside our group to help fight financial crime. This includes crimes such as fraud, money-laundering and terrorist financing.',
          },
          {
            text: 'We may need to confirm your identity before we provide products or services to you or your business. This may include carrying out fraud checks at the point of sale.',
          },
          {
            text: 'Once you have become a customer of ours, we will share your personal information as needed to help combat fraud and other financial crime. The organisations we share data with are:',
          },
          {
            list: [
              'Registered Fraud Prevention Agencies',
              'Other agencies and bodies acting for the same purpose',
              'Industry databases used for this purpose',
              'Insurers',
            ],
          },
          {
            text: 'Throughout our relationship with you, we and these organisations exchange data to help prevent, deter, detect and investigate fraud and money-laundering.',
          },
          {
            text: "We and these organisations can't use your personal information unless we have a proper reason to do so. It must be needed either for us to obey the law, or for a 'legitimate interest'.",
          },
          {
            text: "When we have a business or commercial reason of our own to use your personal information, this is called a 'legitimate interest'. We will tell you what that is, if we are going to rely on it as the reason for using your personal information. Even then, it must not unfairly go against your interests.",
          },
          {
            text: 'We will use the personal information to:',
          },
          {
            list: [
              'Confirm identities',
              'Help prevent fraud and / or money-laundering',
              'Fulfil any contracts you or your business has with us.',
            ],
          },
          {
            text: 'We or an FPA may allow law enforcement agencies to access your personal information. This is to support their duty to prevent, detect, investigate and prosecute crime.',
          },
          {
            text: 'These other organisations can keep personal information for different lengths of time, up to six years.',
          },

          {
            subheading: 'The information we use',
          },
          {
            text: 'These are some of the kinds of personal information that we use:',
          },
          {
            list: [
              'Name',
              'Date of birth',
              'Residential address',
              'History of where you have lived',
              'Contact details, such as email addresses and phone numbers',
              'Financial data',
              'Whether you have been a victim of fraud',
              'Data about insurance claims you have made',
              "Data about you or your business's products or services",
              'Employment details',
              'Vehicle details',
              'Data that identifies computers or other devices you use to connect to the internet. This includes your Internet Protocol (IP) address.',
            ],
          },

          {
            subheading: 'Automated decisions for fraud prevention',
          },
          {
            text: 'The information we have for you or your business is made up of what you tell us, and data we collect when you use our services, or from third parties we work with.',
          },
          {
            text: 'We and other organisations acting to prevent fraud may process your personal information in systems that look for fraud by studying patterns in the data. We may find that an account or policy is being used in ways that fraudsters work. Or we may notice that an account is being used in a way that is unusual for you or your business. Either of these could indicate a risk of fraud or money-laundering against a customer, the bank or the insurer.',
          },

          {
            subheading: 'How this can affect you',
          },
          {
            text: 'If we or an FPA decide there is a risk of fraud, we may stop activity on the accounts or block access to them. FPAs and cross-industry organisations may also keep a record of the risk that you or your business may pose.',
          },
          {
            text: 'This may result in other organisations refusing to provide you with products or services, or to employ you.',
          },

          {
            subheading:
              'Data transfers out of the UK , Isle of Man, Channel Islands and EEA',
          },
          {
            text: "FPAs and other organisations we share data with for these purposes may send personal information to countries outside the UK, Isle of Man, Channel Islands and European Economic Area ('EEA'). When they do, there will be a contract in place to make sure the recipient protects the data to the same standard as the EEA. This may include following international frameworks for making data sharing secure.",
          },
          {
            text: 'Here is the web page for the information notice of the main Fraud Prevention Agency we use:',
          },
          {
            text: (
              <>
                CIFAS -{' '}
                <Link external href="https://www.cifas.org.uk/fpn">
                  www.cifas.org.uk/fpn
                </Link>
              </>
            ),
          },
        ],
      },
    ],
  },

  dataTransferOutsideOfUk: {
    heading:
      'Sending data outside the UK, Isle of Man, Channel Islands and EEA',
    sectionID: 'sending-data-outside-uk',
    subheading:
      'This section tells you about the safeguards that keep your personal information safe and private, if it is sent outside the UK, Isle of Man, Channel Islands and EEA.',
    firstParagraph:
      "We will only send your data outside of the UK, Isle of Man, Channel Islands and European Economic Area ('EEA') to:",
    dataExceptionListItems: [
      'Follow your instructions.',
      'Comply with a legal duty.',
      'Work with our suppliers who help us to run your accounts and services.',
    ],
    dataExceptionListExamples: [
      "For example, if you book a hotel in Australia with your Lloyds Banking Group card we'll send the details necessary to fulfil that payment to the hotel's bank.",
      'For example, we share information about US citizens with the US tax authority.',
    ],
    secondParagraph:
      "If we do transfer your personal information outside the UK, Isle of Man, Channel Islands and EEA to our suppliers, we will make sure it's protected to the same extent as in the UK, Isle of Man, Channel Islands and EEA. We'll use one of these safeguards:",
    safeguardListItems: [
      'Transfer it to a non-EEA country with privacy laws that give the same protection as the UK, Isle of Man, Channel Islands and EEA. Learn more on the European Commission Justice website.',
      'Put in place a contract with the recipient that means they must protect it to the same standards as the UK, Isle of Man, Channel Islands and EEA. Read more about this here on the European Commission Justice website.',
    ],
    dataProtectionLinkLabel: 'Data protection (europa.eu)',
    dataProtectionLinkHref:
      'https://commission.europa.eu/law/law-topic/data-protection_en',
  },

  fourthInfoPanel: {
    contentSections: [
      {
        sectionHeading: 'The control you have',
        sectionID: 'your-data-privacy-rights',
        sectionBody: [
          {
            subheading: 'Your data privacy rights',
          },
          {
            text: 'This section explains your data privacy rights and how to contact us about them.',
          },
          {
            text: 'You have several rights around use of your personal information. Following is a list of these rights, including a description and, if applicable, how to contact us about them. These rights do not apply in all scenarios.',
          },

          {
            subheading: 'The right to be informed',
          },
          {
            text: 'You have the right to be informed about the collection and use of your personal information. This means that we should provide you with details of how we use your personal information. Â This Data Privacy Notice is an example of this.',
          },

          {
            subheading: 'The right of access',
          },
          {
            text: (
              <>
                You have the right to access a copy of your personal
                information, referred to as a Subject Access Request (SAR).
                Please see the 'How to get a copy of your personal information'
                instructions in the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section.
              </>
            ),
          },

          {
            subheading: 'The right to rectification',
          },
          {
            text: (
              <>
                You have the right to question any information we have about you
                that you think is incorrect. We'll take reasonable steps to
                check this for you and correct it. Please visit one of our
                branches, or see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section if you want to do this.
              </>
            ),
          },

          {
            subheading: 'The right to erasure',
          },
          {
            text: (
              <>
                You have the right to have your personal information deleted or
                removed if there is no reason for us to keep it. This is also
                known as 'the right to be forgotten'. There may be legal or
                other official reasons why we need to keep or use your personal
                information. Please see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section if you think we shouldn't be using it.
              </>
            ),
          },

          {
            subheading: 'The right to restrict processing',
          },
          {
            text: (
              <>
                You have the right to restrict processing of your personal
                information. This means it can only be used for certain things,
                such as legal claims or to exercise legal rights. You can ask us
                to do so if your personal information is not accurate, has been
                used unlawfully, is not relevant anymore, or if you have already
                asked us to stop using your personal information but you are
                waiting for us to tell you if we are allowed to keep on using
                it. If we do restrict your personal information in this way, we
                won't use or share it in other ways while it is restricted.
                Please see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to do this.
              </>
            ),
          },

          {
            subheading: 'The right to data portability',
          },
          {
            text: (
              <>
                You have the right to get certain personal information from us
                as a digital file. This means you can keep and use it yourself,
                and give it to other organisations if you choose to. If you
                want, we will give it to you in an electronic format that can be
                easily re-used, or you can ask us to pass it on to other
                organisations for you. Please see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to do this.
              </>
            ),
          },

          {
            subheading: 'The right to object',
          },
          {
            text: (
              <>
                You have the right to object to us keeping or using your
                personal information. There may be legal or other official
                reasons why we need to keep or use your personal information.
                Please see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section if you think that we shouldn't be using it.
              </>
            ),
          },

          {
            subheading:
              'Rights in relation to automated decision making and profiling',
          },
          {
            text: (
              <>
                You have rights around automated decision making and profiling.
                Automated decision making means a decision made solely by
                automated means, without any human involvement. Profiling means
                the automated processing of your personal information to
                evaluate certain things about you. You have the right to
                information about these kinds of processing, and the right to
                ask for human intervention or to challenge an automated
                decision. You can do this when an automated decision is made
                about you, or you can see the{' '}
                <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to speak to us about this. Please see the{' '}
                <Link href="#how-we-work-out-marketing">
                  'How we work out what marketing you receive'
                </Link>{' '}
                section for more information on profiling, and see the 'How we
                use your information to make automated decisions' section for
                more information on automated decision making.
              </>
            ),
          },

          {
            subheading: 'The right to withdraw consent',
          },
          {
            text: (
              <>
                You have the right to withdraw your consent for us to use your
                personal information at any time. This will only affect the way
                we use personal information when our reason for doing so is that
                we have your consent. If you withdraw your consent, we may not
                be able to provide certain products or services to you. If this
                is so, we will tell you. You can manage your preferences for
                activities like marketing in a few ways. Use your internet or
                mobile banking applications, visit one of our branches, or see
                the <Link href="#how-to-contact-us">'How to contact us'</Link>{' '}
                section to speak to us about this.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'How to contact us',
        sectionID: 'how-to-contact-us',
        sectionBody: [
          {
            text: 'This section gives details of how to contact us about how we use your personal information. It also shows you where you can get in touch with the government regulator.',
          },
          {
            boldText:
              'If you have a question about how we use your personal information, or would like to speak to us about your data privacy right.',
          },
          {
            text: 'If you have any questions, want more details about how we use your personal information, or would like to speak to us about one of your data privacy rights, you can call us on 0345 300 2627. (+44 TEL from outside the UK). We may monitor or record your calls. Lines are open 8:00am to 6:00pm Monday to Friday.',
          },
          {
            text: "If you have a hearing or speech impairment you can also call us using Relay UK. If you use a textphone, just add 18001 before the number you're calling. On a smartphone, tablet or computer, you can call through the Relay UK app. There's more information on the Relay UK help pages.",
          },
          {
            text: (
              <>
                If you're Deaf and a BSL user, you can talk to us live through a
                BSL interpreter online with our{' '}
                <Link
                  external
                  href="https://www.lloydsbank.com/contact-us/sign-video.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1"
                >
                  BSL SignVideo service
                </Link>
                .
              </>
            ),
          },
        ],
      },
    ],
  },

  personalInformation: {
    heading: 'If you want a copy of your personal information.',
    firstParagraph:
      'To get a copy of all the personal information we hold about you, write to us at this address:',
    mailingAddress: [
      'HBOS DSAR',
      'Keens House',
      'Anton Mill Lane',
      'Andover',
      'SP10 2NQ',
    ],
  },

  dataProtectionOfficer: {
    heading: 'If you would like to contact our Group Data Protection Officer',
    firstParagraph:
      'You can contact our Group Data Protection Officer by writing to the following address:',
    mailingAddress: [
      'LBG Data Protection Officer',
      'Lloyds Banking Group',
      'PO BOX 548',
      'Leeds',
      'LS1 1WU',
    ],
  },

  unhappyWithDataUsage: {
    heading:
      'If you are unhappy with how we have used your personal information',
    firstParagraph:
      'If you are unhappy with how we have used your personal information, you can contact us on 0345 300 2627. Lines are open 8:00am to 6:00pm Monday to Friday. Calls may be monitored or recorded.',
    secondParagraph: 'Or you can write to us at:',
    mailingAddress: [
      'Birmingham Midshires',
      'Pendeford Business Park',
      'Wobaston Road',
      'Wolverhampton',
      'WV9 5HA',
    ],
  },

  fifthInfoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            subheading: 'If you are not happy with the outcome of a complaint',
          },
          {
            text: "You also have the right to complain to the regulator, and to lodge an appeal if you're not happy with the outcome of a complaint.",
          },
          {
            text: (
              <>
                In the UK this is the Information Commissioner's Office. Find
                out on their website how to{' '}
                <Link external href="https://www.ico.org.uk/concerns">
                  report a concern
                </Link>
                .
              </>
            ),
          },
          {
            text: (
              <>
                In Jersey, please contact the{' '}
                <Link external href="https://jerseyoic.org/make-a-complaint">
                  Office of the Information Commissioner
                </Link>
              </>
            ),
          },
          {
            text: (
              <>
                In Guernsey, please contact the{' '}
                <Link external href="https://www.odpa.gg/">
                  Office of the Data Protection Commissioner
                </Link>
              </>
            ),
          },
          {
            text: (
              <>
                In Isle of Man, please contact the{' '}
                <Link external href="https://www.inforights.im/contact-us">
                  Information Commissioner
                </Link>
              </>
            ),
          },
        ],
      },
    ],
  },
};

export default privacy;
