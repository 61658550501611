import React, { ComponentProps } from 'react';

import { Link } from '@constellation/core';
import {
  LinkProps,
  Link as ReactRouterLink,
} from '@interstellar/react-app-routing';

type StyledLinkProps = ComponentProps<typeof Link> & Pick<LinkProps, 'to'>;

export function StyledLink({
  children,
  ...rest
}: StyledLinkProps): React.JSX.Element {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Link as={ReactRouterLink} {...rest}>
      {children}
    </Link>
  );
}
