import { accessibility } from './accessibility';
import { bankOfEnglandBaseRate } from './bankOfEnglandBaseRate';
import { complaints } from './complaints';
import { contactUs } from './contactUs';
import { cookiesUpperInfoPanel, cookiesLowerInfoPanel } from './cookies';
import { eligibilityPage } from './eligibility';
import { feesAndCharges } from './feesAndCharges';
import { financialDifficultiesPage } from './financialDifficulties';
import { homePage } from './homePage';
import { howToMakeAPaymentPage } from './howToMakeAPayment';
import { interestAndProductsPage } from './InterestAndPayments';
import { knowledgeBasePage } from './knowledgeBase';
import { lendingPage } from './lendingPage';
import { makingOverpaymentsPage } from './makingOverpayments';
import { mortgageCalculatorsPage } from './mortgageCalculatorsPage';
import { mortgagePaymentCalculator } from './mortgagePaymentCalculator';
import { navigationMenu } from './navigationMenu';
import { ourMortgagesPage } from './ourMortgages';
import { paymentsPage } from './paymentPage';
import { portfolioLandlord } from './portfolioLandlord';
import { privacy } from './privacy';
import { propertyDeeds } from './propertyDeeds';
import { rateChangeCalculator } from './rateChangeCalculator';
import { supportPage } from './supportPage';
import { sustainability } from './sustainability';
import { termsAndConditions } from './termsAndConditions';

export default {
  content: {
    container: 'content-container',
  },
  navigationMenu,
  homePage,
  supportPage,
  paymentsPage,
  knowledgeBasePage,
  howToMakeAPaymentPage,
  eligibilityPage,

  sustainability,
  makingOverpaymentsPage,
  lendingPage,
  interestAndProductsPage,
  ourMortgagesPage,

  mortgageCalculatorsPage,
  contactUs,
  portfolioLandlord,
  financialDifficultiesPage,
  bankOfEnglandBaseRate,
  feesAndCharges,
  complaints,
  propertyDeeds,
  rateChangeCalculator,
  cookiesUpperInfoPanel,
  cookiesLowerInfoPanel,
  termsAndConditions,
  accessibility,
  mortgagePaymentCalculator,
  privacy,
};
