import React from 'react';

import {
  Button,
  Container,
  Grid,
  Heading,
  Link,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import bmFiveStarImg from 'assets/common/BM-5-start-winner.png';
import borrowMoreImage from 'assets/common/borrow-more.jpg';
import sustainabilityImg from 'assets/common/homePageSustainabilityImage.jpg';
import whatWeOfferImg from 'assets/common/House_white_CMYK.png';
import manageYourMortgageImage from 'assets/common/manage-your-mortgage.jpg';
import switchDealImage from 'assets/common/switch-deal.jpg';
import GradientBox from 'client/components/gradientBox/GradientBox';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import HeroComponent from './hero/HeroComponent';
import { HomePageContent } from './HomePage.config';
import {
  StyledSustainabilityBox,
  FullWidthImage,
  StyledGridItem,
} from './HomePage.styled';
import ContentBox from '../../../components/contentBox/ContentBox';
import * as routes from '../../manifest';

function HomePage(): React.JSX.Element {
  const {
    heroContent,
    whoWeAre,
    whatWeOffer,
    supportForExisting,
    sustainability,
  } = useContent<HomePageContent>();

  const navigate = useNavigate();

  return (
    <Main>
      <Helmet>
        <title>Home | Birmingham Midshires </title>
      </Helmet>
      <HeroComponent
        caption={heroContent.imageCaption}
        subtext={heroContent.imageSubtext}
        btnLabel={heroContent.signInBtnLabel}
        btnHref={heroContent.signInBtnHref}
        testId={testIds.homePage.heroComponent}
      />

      <Container marginTop="05" marginBottom="05">
        <ContentBox
          imgSrc={bmFiveStarImg}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.homePage.whoWeAreImg}
        >
          <Heading data-testid={testIds.homePage.whoWeAreHeading}>
            {whoWeAre.heading}
          </Heading>
          <Paragraph data-testid={testIds.homePage.whoWeAreFirstParagraph}>
            {whoWeAre.firstParagraph}
          </Paragraph>
          <Paragraph data-testid={testIds.homePage.whoWeAreSecondParagraph}>
            {whoWeAre.secondParagraph}
          </Paragraph>
        </ContentBox>
      </Container>

      <GradientBox bgColor="brand1">
        <ContentBox
          imgSrc={whatWeOfferImg}
          leftAlignImage
          imgWidth="200px"
          marginBottom="05"
          marginTop="05"
          imgTestId={testIds.homePage.whatWeOfferImg}
        >
          <Heading inverse data-testid={testIds.homePage.whatWeOfferHeading}>
            {whatWeOffer.heading}
          </Heading>
          <Paragraph
            inverse
            data-testid={testIds.homePage.whatWeOfferFirstParagraph}
          >
            {whatWeOffer.firstParagraph}
          </Paragraph>
          <Paragraph
            inverse
            data-testid={testIds.homePage.whatWeOfferSecondParagraph}
          >
            {whatWeOffer.secondParagraph}
          </Paragraph>
          <Button
            onClick={() => {
              navigate(routes.ContactUs);
            }}
            data-testid={testIds.homePage.whatWeOfferButton}
          >
            {whatWeOffer.contactUsBtnLabel}
          </Button>
        </ContentBox>
      </GradientBox>

      <Heading
        as="h3"
        size="s4"
        data-testid={testIds.homePage.existingCustomerHelpHeading}
      >
        {supportForExisting.heading}
      </Heading>
      <Paragraph data-testid={testIds.homePage.existingCustomerHelpSubheading}>
        {supportForExisting.subheading}
      </Paragraph>
      <Grid>
        <StyledGridItem md={4} sm={12}>
          <FullWidthImage
            src={switchDealImage}
            data-testid={testIds.homePage.switchYourMortgageImg}
          />
          <Heading
            marginTop="02"
            marginBottom="02"
            data-testid={testIds.homePage.switchYourMortgageHeading}
          >
            {supportForExisting.switchMortgage.heading}
          </Heading>
          <Paragraph
            size="s3"
            data-testid={testIds.homePage.switchYourMortgageSubheading}
          >
            {supportForExisting.switchMortgage.subheading}
          </Paragraph>
          <Link
            weight="bold"
            as={Button}
            data-testid={testIds.homePage.switchYourMortgageButton}
            href={supportForExisting.switchMortgage.buttonHref}
          >
            {supportForExisting.switchMortgage.buttonText}
          </Link>
        </StyledGridItem>
        <StyledGridItem md={4} sm={12}>
          <FullWidthImage
            src={manageYourMortgageImage}
            data-testid={testIds.homePage.manageYourMortgageImg}
          />
          <Heading
            marginTop="02"
            marginBottom="02"
            data-testid={testIds.homePage.manageYourMortgageHeading}
          >
            {supportForExisting.manageMortgage.heading}
          </Heading>
          <Paragraph
            size="s3"
            data-testid={testIds.homePage.manageYourMortgageSubheading}
          >
            {supportForExisting.manageMortgage.subheading}
          </Paragraph>
          <Button
            data-testid={testIds.homePage.manageYourMortgageButton}
            onClick={() => {
              navigate(routes.ManageYourMortgage);
            }}
          >
            {supportForExisting.manageMortgage.buttonText}
          </Button>
        </StyledGridItem>
        <StyledGridItem md={4} sm={12}>
          <FullWidthImage
            src={borrowMoreImage}
            data-testid={testIds.homePage.borrowMoreImg}
          />
          <Heading
            marginTop="02"
            marginBottom="02"
            data-testid={testIds.homePage.borrowMoreHeading}
          >
            {supportForExisting.borrowMore.heading}
          </Heading>
          <Paragraph
            size="s3"
            data-testid={testIds.homePage.borrowMoreSubHeading}
          >
            {supportForExisting.borrowMore.subheading}
          </Paragraph>
          <Link
            weight="bold"
            as={Button}
            data-testid={testIds.homePage.borrowMoreButton}
            href={supportForExisting.borrowMore.buttonHref}
          >
            {supportForExisting.borrowMore.buttonText}
          </Link>
        </StyledGridItem>
      </Grid>

      <StyledSustainabilityBox>
        <ContentBox
          imgSrc={sustainabilityImg}
          leftAlignImage
          imgWidth="300px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.homePage.sustainabilityImage}
        >
          <Heading data-testid={testIds.homePage.sustainabilityHeading}>
            {sustainability.heading}
          </Heading>
          <Paragraph
            data-testid={testIds.homePage.sustainabilityFirstParagraph}
          >
            {sustainability.firstParagraph}
          </Paragraph>
          <Button
            variation="secondary"
            data-testid={testIds.homePage.sustainabilityButton}
            onClick={() => {
              navigate(routes.Sustainability);
            }}
          >
            {sustainability.findOutMoreBtnLabel}
          </Button>
        </ContentBox>
      </StyledSustainabilityBox>
    </Main>
  );
}

export default HomePage;
