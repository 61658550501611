import React from 'react';

import {
  ContentGroup,
  Heading,
  List,
  ListItem,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { ComplaintsPageContent } from './ComplaintsPage.config';
import * as routes from '../../manifest';

export default function ComplaintsPage() {
  const { pageHeading, byPhoneSection, byPostSection, infoPanel } =
    useContent<ComplaintsPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Complaints | Knowledge Base | Birmingham Midshires </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back
        </StyledLink>
        <Heading as="h1" marginTop="05" size="s7">
          {pageHeading}
        </Heading>

        <Heading as="h3" size="s4">
          {byPhoneSection.title}
        </Heading>

        <Paragraph>{byPhoneSection.text}</Paragraph>

        <Heading as="h3" size="s4">
          {byPostSection.title}
        </Heading>

        <Paragraph>{byPostSection.writeToText}</Paragraph>

        <Paragraph marginBottom="none">{byPostSection.address1}</Paragraph>
        <Paragraph marginBottom="none">{byPostSection.address2}</Paragraph>
        <Paragraph marginBottom="none">{byPostSection.address3}</Paragraph>
        <Paragraph marginBottom="none">{byPostSection.address4}</Paragraph>
        <Paragraph marginBottom="none">{byPostSection.address5}</Paragraph>
        <Paragraph>{byPostSection.address6}</Paragraph>

        <Paragraph>{byPostSection.includeText}</Paragraph>

        <List>
          <ListItem>{byPostSection.includeItem1}</ListItem>
          <ListItem>{byPostSection.includeItem2}</ListItem>
        </List>

        <InfoPanel infoPanel={infoPanel} pageTestIds={testIds.complaints} />
      </ContentGroup>
    </Main>
  );
}
