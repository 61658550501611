const isValidTermLengthMonths = (years: number, months: number) => {
  if (
    months === undefined ||
    months > 11 ||
    (Number(years) === 0 && Number(months) === 0)
  ) {
    return false;
  }
  if (isNaN(months) || months.toString() === '') {
    return false;
  }
  return true;
};

export default isValidTermLengthMonths;
