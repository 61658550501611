import { groupIntegerByThousands } from "./groupIntegerByThousands";

export const formatNumber = (value: string | number): string => {
    if (value !== 0 && !value) return '';

    const workingValue = typeof value === 'string' ? Number.parseFloat(value) : value;
    const [integerPart] = Math.abs(workingValue).toString().split('.');
    const integer = groupIntegerByThousands(integerPart);
  
    return `£${integer}`;
  };