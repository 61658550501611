import React from 'react';

import { Heading, Accordion } from '@constellation/core';

import { ContentSection, InfoPanelContent } from './InfoPanel.config';
import { SectionBody } from '../sectionBody';

type Props = { infoPanel: InfoPanelContent; pageTestIds: any };

function InfoPanel({ infoPanel, pageTestIds }: Props) {
  return (
    <>
      {infoPanel.contentSections.map((section: ContentSection, sectionIndex) =>
        section.isAccordian ? (
          <React.Fragment key={`${pageTestIds.sectionHeading}`}>
            <Accordion
              id={section.sectionID}
              label={section.sectionHeading}
              variation="inpage1"
              data-testid={`${pageTestIds.sectionHeading}-${sectionIndex}`}
            >
              <SectionBody
                section={section}
                pageId={pageTestIds.sectionBody}
                sectionIndex={sectionIndex}
              />
            </Accordion>
          </React.Fragment>
        ) : (
          <React.Fragment key={`${pageTestIds.sectionHeading}`}>
            {section.sectionHeading && (
              <Heading
                id={section.sectionID}
                data-testid={`${pageTestIds.sectionHeading}-${sectionIndex}`}
              >
                {section.sectionHeading}
              </Heading>
            )}
            <SectionBody
              section={section}
              pageId={pageTestIds.sectionBody}
              sectionIndex={sectionIndex}
            />
          </React.Fragment>
        ),
      )}
    </>
  );
}

export default InfoPanel;
