import React from 'react';

import { ContentGroup, Heading, Main } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { BankOfEnglandBaseRateContent } from './BankOfEnglandBaseRate.config';
import * as routes from '../../manifest';

function BankOfEnglandBaseRate(): React.JSX.Element {
  const { pageHeading, infoPanel } = useContent<BankOfEnglandBaseRateContent>();

  return (
    <Main>
      <Helmet>
        <title>
          Bank of England base rate | Knowledge Base | Birmingham Midshires{' '}
        </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back
        </StyledLink>
        <Heading as="h1" marginTop="05" size="s7">
          {pageHeading}
        </Heading>

        <InfoPanel
          pageTestIds={testIds.bankOfEnglandBaseRate}
          infoPanel={infoPanel}
        />
      </ContentGroup>
    </Main>
  );
}

export default BankOfEnglandBaseRate;
