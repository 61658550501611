import React, { ReactElement } from 'react';

import birminghamMidshiresLogo from 'assets/common/birmingham-midshires.png';
import AppLayout from 'client/components/appLayout/AppLayout';
import { ScreenProvider } from 'client/context/ScreenContext';

import Routes from '../../routes';
import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import useAppConfig from '../appConfig/useAppConfig';
import { BrandProvider, Brand } from '../brandProvider';
import ScrollToTop from '../scrollToTop/ScrollToTop';

function App(): ReactElement {
  const configBrand = useAppConfig().BRAND_NAME;
  const brand: Brand = getThemeFromUrl() || configBrand;

  return (
    <BrandProvider brand={brand}>
      <ScreenProvider>
        <AppLayout logo={birminghamMidshiresLogo}>
          <ScrollToTop />
          <Routes />
        </AppLayout>
      </ScreenProvider>
    </BrandProvider>
  );
}

export default App;
