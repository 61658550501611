const isValidTermLengthYears = (years: number) => {
  if (years === undefined || years < 0 || years > 50) {
    return false;
  }
  if (isNaN(years) || years.toString() === '') {
    return false;
  }
  return true;
};

export default isValidTermLengthYears;
