import React from 'react';

import { Box, ListItem, Paragraph, Text, Heading } from '@constellation/core';

import { StyledList } from './SectionBody.styled';
import { ContentSection, SectionText } from '../infoPanel/InfoPanel.config';

type SectionBodyProps = {
  section: ContentSection;
  pageId: string;
  sectionIndex: number;
};

export function SectionBody({
  section,
  pageId,
  sectionIndex,
}: SectionBodyProps) {
  return (
    <Box
      bgColor="default2"
      padding="none"
      data-testid={`${pageId}-${sectionIndex}`}
    >
      {section.sectionBody.map((item: SectionText, index) => (
        <React.Fragment
          key={`text-item-${item.subheading || item.text || item.boldText || item.link || 'section-body'}`}
        >
          {item.boldText && (
            <Paragraph
              weight="bold"
              data-testid={`${pageId}-${sectionIndex}-item-${index}`}
            >
              {item.boldText}
            </Paragraph>
          )}
          {item.text && (
            <Paragraph data-testid={`${pageId}-${sectionIndex}-item-${index}`}>
              {item.text}
            </Paragraph>
          )}
          {item.subheading && (
            <Heading
              size="s3"
              marginBottom="02"
              data-testid={`${pageId}-${sectionIndex}-item-${index}`}
            >
              {item.subheading}
            </Heading>
          )}
          {item.keyValueList && (
            <Paragraph data-testid={`${pageId}-${sectionIndex}-item-${index}`}>
              {item.keyValueList.map((listItem) => (
                <Text key={listItem}>{listItem}</Text>
              ))}
            </Paragraph>
          )}
          {item.list && (
            <StyledList
              marginBottom="02"
              data-testid={`${pageId}-${sectionIndex}-item-${index}`}
            >
              {item.list.map((listItem) => (
                <ListItem key={`defined-list-item-${listItem}`}>
                  {listItem}
                </ListItem>
              ))}
            </StyledList>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default SectionBody;
