import { Grid } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledGrid: StyledComponent<typeof Grid, any> = styled<
  typeof Grid
>(Grid)`
  &&&{
    margin: 0 auto;
    max-width: 75rem;
  }
`;
