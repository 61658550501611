import React from 'react';

import { Main, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { AccessibilityPageContent } from './AccessibilityPage.config';

export default function AccessibilityPage() {
  const { pageHeading, infoPanel } = useContent<AccessibilityPageContent>();
  return (
    <Main>
      <Helmet>
        <title>Accessibility | Birmingham Midshires </title>
      </Helmet>
      <Heading as="h1" size="s7" data-testid={testIds.accessibility.heading}>
        {pageHeading}
      </Heading>

      <InfoPanel infoPanel={infoPanel} pageTestIds={testIds.accessibility} />
    </Main>
  );
}
