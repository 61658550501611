import React from 'react';

import HeadingProps from '@constellation/core/dist/types/components/DisplayText/displayText.types';
import { useScreen } from 'client/context/ScreenContext';

import { FlexContainer, FlexImg, FlexTextBox } from './ContentBox.styled';

type Props = {
  imgSrc: any;
  imgWidth: string;
  children: any;
  marginTop: HeadingProps["marginTop"];
  marginBottom: HeadingProps["marginBottom"];
  leftAlignImage: boolean;
  imgTestId?: string;
};

function ContentBox({ imgSrc, imgWidth, children, marginTop, marginBottom, leftAlignImage, imgTestId }: Props): React.JSX.Element {
  const { isMobile } = useScreen();


  function determineFlexDirection() {
    if (isMobile) {
      return leftAlignImage ? "column-reverse" : "column";
    }

    return leftAlignImage ? 'row-reverse' : 'row';
  }

  function determineJustifyContent() {
    if (leftAlignImage) {
      return "flex-start";
    }

    return "flex-end";
  }

  function determineTextBoxWidth() {
    /* Desktop view right aligned content boxes take 75% as the text in page banners shouldn't take up the full space */
    if (!leftAlignImage && !isMobile) {
      return "75%";
    }

    return "100%";
  }

  function determineTextBoxMarginLeft() {
    if (leftAlignImage && !isMobile) {
      return '05';
    }

    return 'none';
  }

  return (
    <FlexContainer justifyContent={determineJustifyContent()} flexDirection={determineFlexDirection()} marginTop={marginTop} marginBottom={marginBottom} >
      <FlexTextBox marginTop={isMobile ? '04' : 'none'} marginLeft={determineTextBoxMarginLeft()} maxWidth={determineTextBoxWidth()}>
        {children}
      </FlexTextBox>
      <FlexImg src={imgSrc} maxWidth={imgWidth} data-testid={imgTestId} />
    </FlexContainer>
  );
}

export default ContentBox;