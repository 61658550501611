import { AppContent } from 'content/AppContent';

const home: AppContent['home'] = {
  heroContent: {
    imageCaption: 'Manage your mortgage wherever you are',
    imageSubtext: 'Try our online portal.',
    signInBtnLabel: 'Sign in',
    signInBtnHref: 'https://online.bmmortgages.co.uk/dc/sign-in'
  },

  whoWeAre: {
    heading: 'Who we are',
    firstParagraph: "We've been around for over 150 years, starting life back in the 19th Century when lots of building societies came together to form Birmingham Midshires.",
    secondParagraph: "Today, we're a specialist provider offering you a smarter choice with buy to let mortgages."
  },

  whatWeOffer: {
    heading: 'What we offer',
    firstParagraph: "As a specialist buy to let provider, we want you to feel confident you've made the right decision with your mortgage. We look after the details, so you can focus on getting the best deal, safe in the knowledge the benefits have been passed on to you.",
    secondParagraph: "We're always easy to deal with and will respond quickly to any questions you might have.",
    contactUsBtnLabel: 'Contact us',
  },

  supportForExisting: {
    heading: 'Existing customer help',
    subheading:
      "If you've already got a mortgage with us, check out how we can help you here.",
    switchMortgage: {
      heading: 'Time to switch your mortgage deal?',
      subheading:
        "If your current mortgage deal's ending soon,   learn all about switching your deal here.",
      buttonText: 'Switch deal',
      buttonHref: 'https://online.bmmortgages.co.uk/dc/product-transfer',
    },
    manageMortgage: {
      heading: 'Manage your mortgage',
      subheading:
        'Already got a mortgage with us? Find out how to manage it online, borrow more, switch your deal, and everything else you need to know.',
      buttonText: 'Manage your mortgage',
    },
    borrowMore: {
      heading: 'Ready to borrow more?',
      subheading:
        'Understand what you need to know if you   want to borrow more on your mortgage.',
      buttonText: 'Borrow more',
      buttonHref: 'https://online.bmmortgages.co.uk/dc/borrow-more',
    },
  },

  sustainability: {
    heading: 'Sustainability',
    firstParagraph: 'Find out more about your obligations as a landlord and what government schemes are available to help you and your tenants make your property more sustainable.',
    findOutMoreBtnLabel: 'Find out more about sustainability',
  }
};

export default home;
