import React from 'react';

import { Heading, Main } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { PaymentsPageContent } from './PaymentsPage.config';

export default function PaymentsPage() {
  const { pageHeading, infoPanel } = useContent<PaymentsPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Payments | Birmingham Midshires </title>
      </Helmet>
      <Heading
        as="h1"
        size="s7"
        data-testid={`${testIds.paymentsPage.heading}`}
      >
        {pageHeading}
      </Heading>

      <InfoPanel infoPanel={infoPanel} pageTestIds={testIds.paymentsPage} />
    </Main>
  );
}
