import React, { useState } from 'react';

import {
  Dialog,
  Heading,
  Link,
  ListItem,
  Paragraph,
  Main,
  Button,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledList } from 'client/components/sectionBody/SectionBody.styled';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { EligibilityContent } from './EligibilityPage.config';

function EligibilityPage(): React.JSX.Element {
  const {
    pageHeading,
    tooltipText,
    mortgageMustHaveSection,
    infoPanelOne,
    propertyMustSection,
    tenantMustSection,
    importantToKnow,
    downloadOurGuideButton,
    decisionInPrinciple,
    mortgageBroker,
  } = useContent<EligibilityContent>();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Main>
      <Helmet>
        <title>Eligibility | Our Mortgages | Birmingham Midshires </title>
      </Helmet>
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          data-testid={testIds.eligibilityPage.modal}
          onClose={() => setDialogOpen(false)}
        >
          <Paragraph data-testid={testIds.eligibilityPage.modalParagraph}>
            {tooltipText}
          </Paragraph>
        </Dialog>
      )}
      <Heading as="h1" size="s7" marginBottom="03">
        {pageHeading}
      </Heading>

      <InfoPanel
        pageTestIds={testIds.eligibilityPage}
        infoPanel={infoPanelOne}
      />

      <Heading as="h3" size="s4">
        {mortgageMustHaveSection.mortgageMustHave}
      </Heading>

      <StyledList>
        <ListItem>{mortgageMustHaveSection.mortgageMustHaveListItem1}</ListItem>
        <ListItem>{mortgageMustHaveSection.mortgageMustHaveListItem2}</ListItem>
        <ListItem>
          {mortgageMustHaveSection.mortgageMustHaveListItem3One}{' '}
          <Link
            data-testid={testIds.eligibilityPage.modalLink}
            onClick={() => {
              setDialogOpen(!dialogOpen);
            }}
          >
            {mortgageMustHaveSection.mortgageMustHaveListItem3Two}
          </Link>{' '}
          {mortgageMustHaveSection.mortgageMustHaveListItem3Three}
        </ListItem>
      </StyledList>

      <Heading as="h3" size="s4">
        {propertyMustSection.heading}
      </Heading>

      <StyledList>
        {propertyMustSection.list.map((item: string) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </StyledList>

      <Heading as="h3" size="s4">
        {tenantMustSection.heading}
      </Heading>

      <StyledList>
        {tenantMustSection.list.map((item: string) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </StyledList>

      <Heading as="h3" size="s4">
        {importantToKnow.heading}
      </Heading>

      <StyledList>
        {importantToKnow.list.map((item: string) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </StyledList>

      <Paragraph>{importantToKnow.eligibilityText}</Paragraph>

      <Link
        as={Button}
        external
        data-testid={testIds.eligibilityPage.button}
        href={downloadOurGuideButton.url}
      >
        {downloadOurGuideButton.label}
      </Link>

      <Heading as="h3" size="s4" marginTop="05">
        {decisionInPrinciple.heading}
      </Heading>

      <Paragraph>{decisionInPrinciple.introText}</Paragraph>

      <StyledList>
        {decisionInPrinciple.list.map((item: string) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </StyledList>

      <Paragraph>{mortgageBroker}</Paragraph>
    </Main>
  );
}

export default EligibilityPage;
