import React from 'react';

import { ErrorSummary, ErrorSummaryItem } from '@constellation/core';

interface ErrorBuilderProps {
  errors: object;
  errorTitle: string;
  testId: string;
}

function ErrorSummaryBuilder({
  errors,
  errorTitle,
  testId
}: ErrorBuilderProps) {
  return (
    <ErrorSummary title={errorTitle} data-testid={testId}>
      {Object.entries(errors).map(
        ([fieldError, fieldErrorValue]) => (
          <ErrorSummaryItem
            key={`validation-item-${fieldError}`}
            href={`#${fieldError}-error`}
            data-testid={`${fieldError}-summary-error`}
          >
            {fieldErrorValue}
          </ErrorSummaryItem>
        ),
      )}
    </ErrorSummary>
  );
}
export default ErrorSummaryBuilder;
