export const cookiesUpperInfoPanel = {
  heading: 'cookies-policy-page-heading',
  sectionHeading: 'cookes-policy-upper-section-heading',
  sectionBody: 'cookies-policy-upper-section-body',

  thirdPartyTable: 'third-party-table',
  essentialCookiesTable: 'essential-cookies-table',

  essentialCookiesHeading: 'essential-cookies-heading',
  essentialCookiesSubheading: 'essential-cookies-subheading',
}

export const cookiesLowerInfoPanel = {
  sectionHeading: 'cookes-policy-lower-section-heading',
  sectionBody: 'cookies-policy-lower-section-body'
}