const isValidInterestRateChange = (
  interestRateField: number,
  interestRateChange: number,
) => {
  const convertedInterestRateField = Number(interestRateField);
  const subtractionResult = Number(
    Number(Math.abs(interestRateChange)) - Number(convertedInterestRateField),
  ).toFixed(2);

  if (
    undefined ||
    interestRateChange < -15 ||
    interestRateChange > 15 ||
    Number(interestRateChange) === 0
  ) {
    return false;
  }

  if (
    isNaN(interestRateChange) ||
    (Math.sign(interestRateChange) === -1 &&
      Number(Math.abs(interestRateChange) > interestRateField))
  ) {
    return false;
  }

  if (
    Math.sign(interestRateChange) === -1 &&
    Math.abs(Number(subtractionResult)) < 0.1
  ) {
    return false;
  }

  return true;
};

export default isValidInterestRateChange;
