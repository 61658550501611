import React from 'react';

import { Main, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { TermsAndConditionsContent } from './TermsAndConditionsPage.config';

export default function TermsAndConditionsPage() {
  const { heading, infoPanel } = useContent<TermsAndConditionsContent>();

  return (
    <Main>
      <Helmet>
        <title>Terms and conditions | Birmingham Midshires </title>
      </Helmet>
      <Heading
        as="h1"
        size="s7"
        data-testid={testIds.termsAndConditions.heading}
      >
        {heading}
      </Heading>

      <InfoPanel
        infoPanel={infoPanel}
        pageTestIds={testIds.termsAndConditions}
      />
    </Main>
  );
}
