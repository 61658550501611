export const sustainability = {
  headerImg: 'sustainability-heading-image',
  headerTitle: 'sustainability-heading-text',
  headerSubheading: 'sustainability-subheading',

  epcRatingHeading: 'epc-rating-heading',
  epcRatingFirstParagraph: 'epc-rating-first-paragraph',
  epcRatingSecondParagraph: 'epc-rating-second-paragraph',
  epcRatingLinkEng: 'epc-rating-link-eng',
  epcRatingThirdParagraph: 'epc-rating-third-paragraph',
  epcRatingLinkScot: 'epc-rating-link-scot',

  epcAssessmentHeading: 'epc-assessment-heading',
  epcAssessmentFirstParagraph: 'epc-assessment-first-paragraph',
  epcAssessmentSecondParagraph: 'epc-assessment-second-paragraph',

  governmentSchemesHeading: 'government-schemes-heading',
  governmentSchemesFirstParagraph: 'government-schemes-first-paragraph',

  retrofitToolInfoBox: 'landlord-retrofit-tool-info-box',
}