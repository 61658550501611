export const mortgagePaymentCalculator = {
  heading: 'mortgage-payment-calculator-heading',
  mortgageBalanceField:
    'mortgage-payment-calculator-form-field-mortgage-balance',
  termLengthYearsField:
    'mortgage-payment-calculator-form-field-term-length-years',
  termLengthMonthsField:
    'mortgage-payment-calculator-form-field-term-length-months',
  interestField: 'mortgage-payment-calculator-form-field-interest-rate',
  repaymentTypeRepayment: 'mortgage-payment-calculator-form-field-repayment',
  repaymentTypeInterestOnly:
    'mortgage-payment-calculator-form-field-interest-only',
  secondScenarioMortgageBalanceField:
    'mortgage-payment-calculator-form-field-scenario-two-mortgage-balance',
  secondScenarioTermLengthYearsField:
    'mortgage-payment-calculator-form-field-scenario-two-term-length-years',
  secondScenarioTermLengthMonthsField:
    'mortgage-payment-calculator-form-field-scenario-two-term-length-months',
  secondScenarioInterestField:
    'mortgage-payment-calculator-form-field-scenario-two-interest-rate',
  secondScenarioRepaymentTypeRepayment:
    'mortgage-payment-calculator-form-field-scenario-two-repayment',
  secondScenarioRepaymentTypeInterestOnly:
    'mortgage-payment-calculator-form-field-scenario-two-interest-only',
  toggleScenarioBtn: 'mortgage-payment-calculator-toggle-scenario-button',
  removeScenarioBtn: 'mortgage-payment-calculator-remove-scenario-button',
  calculateBtn: 'mortgage-payment-calculator-calculate-button',
  errorSummaryContainer: 'mortgage-payment-calculator-error-summary-container',
  scenarioOnePayment: 'mortgage-payment-calculator-scenario-one-payment',
  scenarioTwoPayment: 'mortgage-payment-calculator-scenario-two-payment',
  firstScenarioContainer: 'mortgage-payment-calculator-scenario-one-container',
  secondScenarioContainer: 'mortgage-payment-calculator-scenario-two-container',
  monthlyPaymentContainer:
    'mortgage-payment-calculator-monthly-payment-container',
  monthlyPaymentChange:
    'mortgage-payment-calculator-monthly-payment-change-heading',
};
