/* eslint-disable import/prefer-default-export */
import { Button } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledManageYourMortgageOnline = styled.img`
  margin-top: 24px;
  width: 100%;
  height: 155px;
  object-fit: cover;
  display: block;
`;

export const StyledButton: StyledComponent<typeof Button, any> = styled<
  typeof Button
>(Button)`
    width: auto!important;
`;
