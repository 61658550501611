export const rateChangeCalculator = {
    heading: 'rate-change-calculator-heading',
    mortgageBalanceField: 'rate-change-calculator-form-field-mortgage-balance',
    termLengthYearsField: 'rate-change-calculator-form-field-term-length-years',
    termLengthMonthsField: 'rate-change-calculator-form-field-term-length-months',
    interestField: 'rate-change-calculator-form-field-interest-rate',
    repaymentTypeRepayment: 'rate-change-calculator-form-field-repayment',
    repaymentTypeInterestOnly: 'rate-change-calculator-form-field-interest-only',
    interestFieldChange: 'rate-change-calculator-form-field-interest-rate-change',
    submitBtn: 'rate-change-calculator-submit-button',
    errorSummaryContainer: 'rate-change-calculator-error-summary-container',
    monthlyPaymentChange: 'rate-change-calculator-monthly-payment-change',
    monthlyPaymentContainer: 'rate-change-calculator-monthly-payment-container',
    monthlyPaymentParagraph: 'rate-change-calculator-monthly-payment-change-paragraph',
}