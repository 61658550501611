import React from 'react';

import { Link } from '@constellation/core';

import { AppContent } from '../AppContent';

const propertyDeeds: AppContent['propertyDeeds'] = {
  pageHeading: 'Your property deeds',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Properties in England and Wales',
        sectionBody: [
          {
            text:
              <>
                If your mortgage started after 01 May 2000, your deeds will be held electronically at the <Link external href="https://www.gov.uk/government/organisations/land-registry">Land Registry</Link>.
              </>
          },
          {
            text:
              <>
                If your mortgage started before 01 May 2000, please contact us on <Link href="tel:0345 300 2627">0345 300 2627</Link> for more information.
              </>
          },
        ]
      },
      {
        sectionHeading: 'Properties in Scotland and Northern Ireland',
        sectionBody: [
          {
            text:
              <>
                Please contact us on <Link href="tel:0345 300 2627">0345 300 2627</Link> for more information.
              </>
          }
        ]
      },
      {
        sectionHeading: "If our charge hasn't been registered on your property",
        sectionBody: [
          {
            text: "You'll need to get in touch with your solicitor or conveyancer and ask them to do it."
          }
        ]
      },
      {
        sectionHeading: 'How do I get copies of the deeds?',
        sectionBody: [
          {
            text: 'If we hold the title documents, please contact us. You can also get in touch with the Land Registry.'
          }
        ]
      },
      {
        sectionHeading: 'What will happen to my deeds when my mortgage account closes?',
        sectionBody: [
          {
            text: "If you instruct a solicitor to close your account on your behalf, we'll forward your deeds to them once your mortgage has been settled."
          },
          {
            text: "If you send the money to us, we'll close your mortgage and forward your deeds to the address we have on file for you."
          },
          {
            text: "If we hold the deeds, we'll also remove our charge with the Land Registry and write to let you know we've done this."
          }
        ]
      }
    ]
  }
}

export default propertyDeeds;