import React from 'react';

import { Link, Strong } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';

const cookies: AppContent['cookies'] = {
  pageHeading: 'Cookies policy',


  upperInfoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            text: 'Our Cookies policy explains how we use cookies and similar tracking technologies. It also explains the choices you can make about whether we can put some types of cookies on your PC, tablet or mobile phone.',
          },
          {
            text: <>In this policy, where we say 'cookies' it can also mean similar tracking technologies that collect data while you use our websites and mobile apps, and when you receive, open or respond to emails we send you.More information on the tracking we use in our emails, including how you can stop it, can be found in the <Link href='#email-tracking'>Email Tracking</Link> section below.</>,
          },
          {
            text: 'Data we collect will be held by Bank of Scotland plc which trades as Birmingham Midshires. Bank of Scotland is part of the Lloyds Banking Group. We use this data to:',
          },
          {
            list: [
              'Protect you from fraud and keep improving security',
              'Study how people use our websites and apps and our other services, so we can improve them',
              'Decide which of our products, services and offers may be relevant for you',
              'Tailor the marketing you see on social media, apps and other websites.'
            ]
          },
          {
            text: <>We may share this data with other companies in the Group. We also share some data with outside organisations. Some of them may place their own cookies on your device when you visit our website. You can find out more about how we share data - and who we share it with - in our <StyledLink to={routes.PrivacyPolicy}>Privacy notice</StyledLink>.</>,
          },
          {
            text: 'We do not sell data to organisations outside our Group.'
          }
        ]
      },
      {
        sectionHeading: 'What are cookies?',
        sectionBody: [
          {
            text: 'Cookies are small files that are sent to your PC, mobile phone or tablet when you visit a website. They stay on your device and are sent back to the website they came from when you visit it again. Cookies collect data and send it to us while you use the site. These are written into mobile apps and web pages that you go to on your device.'
          }
        ]
      },
      {
        sectionHeading: 'Session and persistent cookies',
        sectionBody: [
          {
            text: 'All cookies are either session cookies or persistent cookies.'
          },
          {
            text: <><Strong>Session cookies</Strong> last for the length of your visit to a website. They delete themselves when you close your browser. Session cookies can help with security. For instance, they can keep you logged on as you move around a website.</>,
          },
          {
            text: <><Strong>Persistent cookies</Strong> stay on your device when you close your browser. They are used for many jobs. For instance, they can remember your User ID for websites where you log on. They can also store your choices for when you go back to a website.</>,
          },
          {
            text: 'Organisations find them useful to see how people use their websites. They can also be used to tailor the ads and marketing you see on social media, apps and other websites.'
          }
        ],
      },
      {
        sectionHeading: 'First and third party cookies',
        sectionBody: [
          {
            text: 'Whether a cookie is first or third party depends on where it comes from.'
          },
          {
            text: <><Strong>First party cookies</Strong> are set by websites you go to.</>
          },
          {
            text: <><Strong>Third party cookies</Strong> are set by outside organisations such as social media, search engines, other advertising networks, and our business partners.</>
          }
        ]
      },
      {
        sectionHeading: 'Our Third Party Partners',
        sectionBody: [
          {
            text: 'If you have given us your consent, we may allow third parties to collect data by setting their own cookies on your device. If you use our Mobile Banking app, they may also use mobile device identifiers to personalise and measure adverts shown to you.',
          },
          {
            text: "You can change your choices at any time. Select the 'Cookies' link at the bottom of any page of our website and go from there.",
          },
          {
            text: 'If you use one of our mobile apps, you can also change how your mobile device identifier is used by updating your settings in the app.',
          },
          {
            text: 'Here are links to the information notices for our main Third Party Partners.'
          }
        ]
      }
    ]
  },

  thirdPartyTableHeaders: [
    'Google',
    'Oracle Cloud',
    'Facebook',
    'Appnexus (also known as xandr)'
  ],
  thirdPartyTableEntries: [
    [
      <Link href='https://policies.google.com/technologies/ads' external>How Google use cookies in advertising</Link>,
      <Link href='https://policies.google.com/technologies/partner-sites' external>How Google uses the data it collects</Link>,
      <Link href='https://policies.google.com/technologies/types' external>Types of cookies we use with Google</Link>
    ],
    [
      <Link href='https://www.oracle.com/uk/legal/privacy/privacy-policy.html' external>Oracle Data Cloud Privacy Policy</Link>,
      <Link href='https://datacloudoptout.oracle.com/#optout' external>Opt-out from Oracle cookies</Link>
    ],
    [
      <Link href='https://www.facebook.com/policies/cookies/' external>Facebook Cookies Policy</Link>
    ],
    [
      <Link href='https://www.appnexus.com/platform-privacy-policy-072016' external>Privacy Policy</Link>
    ]
  ],
  essentialCookies: {
    heading: 'Essential cookies and cookies you can choose',
    subheading: "Different types of cookies do different jobs on our website. Some are needed to make the website work. We need your consent to use others that are not essential. You can change your choices at any time. Just click the 'Cookies' link at the end of any page.",
    tableHeaders: [
      'Category',
      'What they do',
      'My choices'
    ],

    tableEntries: [
      {
        col1: 'Strictly necessary',
        col2: {
          paragraphs: [
            'These cookies are needed to run our website, to keep it secure if you are logged on and to obey regulations that apply to us.',
            'If you are a customer, they help us know who you are so that you can log on and manage your accounts. They also help us keep your details safe and private.',
            'Other important jobs they do are:'
          ],
          bulletPoints: [
            'Help you move around the site',
            "Tell us if you've been to it before and which pages you went to",
            'Tell us how the site is working, so we can find and fix any problems.'
          ]
        },
        col3: "You can't turn off these cookies"
      },
      {
        col1: 'Functional',
        col2: {
          paragraphs: [
            'These cookies are used for remembering things like:'
          ],
          bulletPoints: [
            'Your user ID on the log on page',
            'Your region or country',
            'Your preferred language',
            'Accessibility options like large font or high contrast pages.'
          ]
        },
        col3: "You can't turn off these cookies"
      },
      {
        col1: 'Performance',
        col2: {
          paragraphs: [
            'These cookies tell us how you and our other customers user our website. We combine all this data together and study it. This helps us to:'
          ],
          bulletPoints: [
            'Improve the performance of our services',
            'Improve the products we provide.'
          ]
        },
        col3: "We'll ask for your consent to use these cookies"
      },
      {
        col1: 'Marketing',
        col2: {
          paragraphs: [
            'These cookies help us decide which of our products, services and offers may be relevant for you.',
            'We may use this data to tailor the marketing and ads you see on our own and other websites and mobile apps, including social media. For instance, you may see our ads on other sites after you have been to our website.',
            'If you turn off marketing cookies, you will still see ads online, but they will not be tailored to things that may interest you.'
          ]
        },
        col3: "We'll ask for your consent to use these cookies"
      }
    ]
  },
  lowerInfoPanel: {
    contentSections: [
      {
        sectionHeading: 'Performance and marketing cookies',
        sectionBody: [
          {
            text: "Lloyds Banking Group is changing its websites to make sure we ask for your consent to use these cookies. It will take some time to update all our systems. Until then, you won't be able to make your choices on some websites run by companies within our Group. This means you may still see tailored marketing and ads from us during this time, even if you would prefer not to. We will update this cookies policy as soon as the work is complete."
          }
        ]
      },
      {
        sectionHeading: 'Keep in mind',
        sectionBody: [
          {
            boldText: 'Multiple users'
          },
          {
            text: 'If more than one person uses your device, the choices set up by other people will apply to you as well unless you change them. For example, if you share a computer with your family, you may see ads based on sites they have visited as well as sites you have visited.',
          },
          {
            boldText: 'Cookies with more than one use'
          },
          {
            text: 'Some of our cookies collect data for more than one use. We will only use these cookies for their essential purposes unless you have given your consent to any other uses they have.'
          },
          {
            boldText: 'Cookies that are already on your device'
          },
          {
            text: 'Turning off one or more types of cookies will not delete any that have been downloaded in the past. We may still use data we collected up to that point, but will stop collecting new data.'
          },
          {
            boldText: 'Managing cookies choices in your browser'
          },
          {
            text: 'You can turn off or delete cookies in your browser. If you do this, it may affect sites that use similar cookies to us.'
          },
          {
            text: "Cookies choices you set in your browser replace any you set on a single website. For instance, if you run an ad blocker on your browser, you won't see ads that are tailored to you, even if you have turned on marketing cookies."
          },
          {
            text: <>Find out how to manage cookies in common browsers (Internet Explorer, Chrome, Firefox and Safari) on the <Link href='https://ico.org.uk/' external>Information Commissioners' Office (ICO) website</Link>.</>
          }
        ]
      },
      {
        sectionHeading: 'Email tracking',
        sectionID: 'email-tracking',
        sectionBody: [
          {
            text: 'This section explains more about the technologies we use to track emails, why we do this, and how you can stop it.'
          },
          {
            text: 'We track emails to help us improve the communications we send. We use small images called pixels within our emails to tell us things like whether you opened the email, how many times and the device you used. We may also set a cookie to find out if you clicked on any links in the email. The image stays in the email but leaves nothing else on your device.'
          },
          {
            boldText: 'How to stop email tracking',
          },
          {
            text: 'You can stop this by closing the email before you download any images or click on any links. You can also set your browser or email program to restrict or block our emails. Sometimes your browser or email program setting will automatically accept cookies. For more details on this, you will need to read the instructions for your browser, email program or device.'
          }
        ]
      }
    ]
  },
}

export default cookies;