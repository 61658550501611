import React from 'react';

import { ContentGroup, Heading, Main } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { InterestAndProductsPageContent } from './InterestAndProductsPage.config';
import * as routes from '../../manifest';

export default function InterestAndProductsPage() {
  const { pageHeading, infoPanel } =
    useContent<InterestAndProductsPageContent>();

  return (
    <Main>
      <Helmet>
        <title>
          Interest rates and our Products | Knowledge Base | Birmingham
          Midshires{' '}
        </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back
        </StyledLink>
        <Heading
          as="h1"
          size="s7"
          marginTop="05"
          data-testid={`${testIds.interestAndProductsPage.heading}`}
        >
          {pageHeading}
        </Heading>

        <InfoPanel
          infoPanel={infoPanel}
          pageTestIds={testIds.interestAndProductsPage}
        />
      </ContentGroup>
    </Main>
  );
}
