import React from 'react';

import { Main, Heading, Container, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { Helmet } from 'react-helmet';

import { SiteMapContent } from './SiteMapPage.config';
import * as routes from '../../manifest';

export default function SiteMapPage() {
  const siteMapContent = useContent<SiteMapContent>();

  return (
    <Main>
      <Helmet>
        <title>Sitemap | Birmingham Midshires </title>
      </Helmet>
      <Heading as="h1" size="s7">
        {siteMapContent.heading}
      </Heading>
      <StyledLink to={routes.Home}>{siteMapContent.home}</StyledLink>

      <Container>
        <StyledLink to={routes.OurMortgages}>
          {siteMapContent.ourMortgages}
        </StyledLink>

        <Container>
          <StyledLink to={routes.Eligibility}>
            {siteMapContent.eligibility}
          </StyledLink>
          <Paragraph marginBottom="none">
            <StyledLink to={routes.PortfolioLandlord}>
              {siteMapContent.portfolioLandlord}
            </StyledLink>
          </Paragraph>
        </Container>

        <StyledLink to={routes.ManageYourMortgage}>
          {siteMapContent.manageYourMortgage}
        </StyledLink>

        <Paragraph marginBottom="none" marginTop="none">
          {' '}
          <StyledLink to={routes.MortgageCalculators}>
            {siteMapContent.mortgageCalculators}
          </StyledLink>
        </Paragraph>
        <Container>
          <Paragraph marginBottom="none" marginTop="none">
            {' '}
            <StyledLink to={routes.RateChangeCalculator}>
              {siteMapContent.rateChangeCalculator}
            </StyledLink>
          </Paragraph>
          <Paragraph marginBottom="none" marginTop="none">
            {' '}
            <StyledLink to={routes.MortgagePaymentCalculator}>
              {siteMapContent.mortgagePaymentCalculator}
            </StyledLink>
          </Paragraph>
        </Container>

        <Paragraph marginBottom="none" marginTop="none">
          {' '}
          <StyledLink to={routes.Sustainability}>
            {siteMapContent.sustainability}
          </StyledLink>
        </Paragraph>

        <Paragraph marginBottom="none">
          <StyledLink to={routes.KnowledgeBase}>
            {siteMapContent.knowledgeBase}
          </StyledLink>
        </Paragraph>
        <Container>
          {siteMapContent.knowledgeBaseArticleLabels.map((_, articleIndex) => (
            <Paragraph marginBottom="none" marginTop="none">
              <StyledLink
                to={siteMapContent.knowledgeBaseArticlesHrefs[articleIndex]}
              >
                {siteMapContent.knowledgeBaseArticleLabels[articleIndex]}
              </StyledLink>
            </Paragraph>
          ))}
        </Container>

        <StyledLink to={routes.ContactUs}>
          {siteMapContent.contactUs}
        </StyledLink>
      </Container>

      <Paragraph marginBottom="none" marginTop="none">
        <StyledLink to={routes.TermsAndConditions}>
          {siteMapContent.termsAndConditions}
        </StyledLink>
      </Paragraph>
      <Paragraph marginBottom="none" marginTop="none">
        <StyledLink to={routes.Accessibility}>
          {siteMapContent.accessibility}
        </StyledLink>
      </Paragraph>
      <Paragraph marginBottom="none" marginTop="none">
        <StyledLink to={routes.CookiesPolicy}>
          {siteMapContent.cookiesPolicy}
        </StyledLink>
      </Paragraph>
      <Paragraph marginBottom="none" marginTop="none">
        <StyledLink to={routes.PrivacyPolicy}>
          {siteMapContent.privacyPolicy}
        </StyledLink>
      </Paragraph>
    </Main>
  );
}
