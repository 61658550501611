import React from 'react';

import BoxProps from '@constellation/core/dist/types/components/Box/box.types';

import { StyledGradientBox } from "./GradientBox.styled";

interface GradientBoxProps {
  children: any;
  bgColor?: BoxProps["bgColor"];
}

function GradientBox({ children, bgColor }: GradientBoxProps): React.JSX.Element {
  return (
    <StyledGradientBox bgColor={bgColor}>
      {children}
    </StyledGradientBox>
  );
}

export default GradientBox;