export const privacy = {
  heading: 'privacy-page-heading',

  firstInfoPanel: {
    sectionHeading: 'privacy-page-first-panel-section-heading',
    sectionBody: 'privacy-page-first-panel-section-body'
  },

  secondInfoPanel: {
    sectionHeading: 'privacy-page-second-panel-section-heading',
    sectionBody: 'privacy-page-second-panel-section-body'
  },

  thirdInfoPanel: {
    sectionHeading: 'privacy-page-third-panel-section-heading',
    sectionBody: 'privacy-page-third-panel-section-body'
  },

  fourthInfoPanel: {
    sectionHeading: 'privacy-page-fourth-panel-section-heading',
    sectionBody: 'privacy-page-fourth-panel-section-body'
  },

  fifthInfoPanel: {
    sectionHeading: 'privacy-page-fifth-panel-section-heading',
    sectionBody: 'privacy-page-fifth-panel-section-body'
  }
}