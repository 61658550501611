import React from 'react';

import {
  Main,
  Heading,
  Strong,
  Table,
  TableCol,
  TableRow,
  Text,
  Paragraph,
  List,
  ListItem,
  ContentGroup,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { CookiesPageContent } from './CookiesPage.config';

export default function CookiesPage(): React.JSX.Element {
  const {
    pageHeading,
    thirdPartyTableEntries,
    thirdPartyTableHeaders,
    essentialCookies,
    upperInfoPanel,
    lowerInfoPanel,
  } = useContent<CookiesPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Cookies | Birmingham Midshires </title>
      </Helmet>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid={testIds.cookiesUpperInfoPanel.heading}
      >
        {pageHeading}
      </Heading>

      <InfoPanel
        infoPanel={upperInfoPanel}
        pageTestIds={testIds.cookiesUpperInfoPanel}
      />

      <Table
        data-testid={testIds.cookiesUpperInfoPanel.thirdPartyTable}
        width="auto"
        responsive
      >
        {thirdPartyTableHeaders.map((entry, colIndex) => (
          <TableRow key={`row-${entry}`}>
            <TableCol key={`header-${entry}`}>
              <Text>{entry}</Text>
            </TableCol>
            <TableCol key={`data-${entry}`}>
              {thirdPartyTableEntries.map((_, rowIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Paragraph
                  key={`data-${entry}-${rowIndex}`}
                  marginBottom="01"
                  marginTop="01"
                >
                  {thirdPartyTableEntries[colIndex][rowIndex]}
                </Paragraph>
              ))}
            </TableCol>
          </TableRow>
        ))}
      </Table>

      <Heading
        as="h2"
        marginTop="05"
        data-testid={testIds.cookiesUpperInfoPanel.essentialCookiesHeading}
      >
        {essentialCookies.heading}
      </Heading>
      <Paragraph
        data-testid={testIds.cookiesUpperInfoPanel.essentialCookiesSubheading}
      >
        {essentialCookies.subheading}
      </Paragraph>

      <Table
        headings={essentialCookies.tableHeaders}
        data-testid={testIds.cookiesUpperInfoPanel.essentialCookiesTable}
      >
        {essentialCookies.tableEntries.map((row) => (
          <TableRow key={`row-${row.col1}`}>
            <TableCol key={`category-${row.col1}`}>
              <Text>
                <Strong>{row.col1}</Strong>
              </Text>
            </TableCol>
            <TableCol key={`what-they-do-${row.col1}`}>
              {row.col2.paragraphs.map((p, pIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Paragraph key={`paragraph-${row.col1}-${pIndex}`}>
                  {p}
                </Paragraph>
              ))}
              {row.col2.bulletPoints && (
                <List>
                  {row.col2.bulletPoints.map((bulletPoint, bpIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={`bullet-${row.col1}-${bpIndex}`}>
                      {bulletPoint}
                    </ListItem>
                  ))}
                </List>
              )}
            </TableCol>
            <TableCol key={`choices-${row.col1}`}>
              <Text>{row.col3}</Text>
            </TableCol>
          </TableRow>
        ))}
      </Table>

      <ContentGroup marginTop="05">
        <InfoPanel
          infoPanel={lowerInfoPanel}
          pageTestIds={testIds.cookiesLowerInfoPanel}
        />
      </ContentGroup>
    </Main>
  );
}
