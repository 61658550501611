import React, { useState } from 'react';

import { Accordion, Box, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useScreen } from 'client/context/ScreenContext';
import testIds from 'client/testIds';
import { StyledGrid } from 'client/utils/global.styled';

import { MenuItem } from './NavigationMenu.config';
import { StyledHr } from './NavigationMenu.styled';
import { AppLayoutContent } from '../appLayout/AppLayout.config';
import { StyledLink } from '../styledLink/StyledLink';

function NavigationMenu(): React.JSX.Element {
  const {
    navigationMenu: { menuItems, menuTitle },
  } = useContent<AppLayoutContent>();

  const { isMobile } = useScreen();

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile ? (
        <Accordion
          label={menuTitle}
          variation="standalone"
          open={isMenuOpen}
          data-testid={`${testIds.navigationMenu.mobileMenu}`}
          onChange={(isOpen) => {
            if (isOpen !== isMenuOpen) {
              setIsMenuOpen(isOpen)
            }
          }}
        >
          {menuItems.map((item: MenuItem, index) => (
            <>
              <StyledLink
                to={item.Url}
                key={`mobile-${testIds.navigationMenu.menuItem}-${item.label}`}
                data-testid={`${testIds.navigationMenu.menuItem}-${index + 1}`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.label}
              </StyledLink>

              <StyledHr
                marginTop="03"
                marginBottom="03"
                key={`${testIds.navigationMenu.menuItemDivider}-${item.label}`}
              />
            </>
          ))}
        </Accordion>
      ) : (
        <Box marginBottom="none">
          <StyledGrid data-testid={`${testIds.navigationMenu.desktopMenu}`}>
            {menuItems.map((item: MenuItem, index) => (
              <Text key={`text-${testIds.navigationMenu.menuItem}-${item.label}`} marginRight="04">
                <StyledLink
                  key={`desktop-${testIds.navigationMenu.menuItem}-${item.label}`}
                  to={item.Url}
                  underline={false}
                  weight="bold"
                  text-decoration="none"
                  data-testid={`${testIds.navigationMenu.menuItem}-${index + 1}`}
                >
                  {item.label}
                </StyledLink>
              </Text>
            ))}
          </StyledGrid>
        </Box>
      )}
    </>
  );
}

export default NavigationMenu;
