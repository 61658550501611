import { NavigationMenuContent } from 'client/components/navigationMenu/NavigationMenu.config';

import * as routes from '../../routes/manifest';

const navigationMenu: NavigationMenuContent = {
  menuTitle: 'Menu',
  menuItems: [
    {
      label: 'Our mortgages',
      Url: routes.OurMortgages,
    },
    {
      label: 'Managing your mortgage',
      Url: routes.ManageYourMortgage,
    },
    {
      label: 'Calculators',
      Url: routes.MortgageCalculators,
    },
    {
      label: 'Sustainability',
      Url: routes.Sustainability,
    },
    {
      label: 'Knowledge base',
      Url: routes.KnowledgeBase,
    },
    {
      label: 'Contact us',
      Url: routes.ContactUs,
    },
  ],
};

export default navigationMenu;
