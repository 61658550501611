export const groupIntegerByThousands = (integerPart: string): string => {
    const firstGroupLength = integerPart.length % 3;
    const groups = [];
    if (firstGroupLength > 0) {
      groups.push(integerPart.slice(0, firstGroupLength));
    }
  
    for (let start = firstGroupLength; start < integerPart.length; start += 3) {
      groups.push(integerPart.slice(start, start + 3));
    }
  
    return groups.join(',');
  };