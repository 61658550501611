import React from 'react';

import {
  Accordion,
  ContentGroup,
  Heading,
  Main,
  Paragraph,
  Strong,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { MakingOverpaymentsPageContent } from './MakingOverpaymentsPage.config';
import * as routes from '../../manifest';

export default function MakingOverpaymentsPage() {
  const { pageHeading, infoPanel, payByCheque, internationalPayments } =
    useContent<MakingOverpaymentsPageContent>();

  return (
    <Main>
      <Helmet>
        <title>
          Making Overpayments | Knowledge Base | Birmingham Midshires{' '}
        </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back
        </StyledLink>
        <Heading
          as="h1"
          size="s7"
          marginTop="05"
          data-testid={`${testIds.makingOverpaymentsPage.heading}`}
        >
          {pageHeading}
        </Heading>

        <InfoPanel
          infoPanel={infoPanel}
          pageTestIds={testIds.makingOverpaymentsPage}
        />
      </ContentGroup>

      <Accordion
        data-testid={testIds.makingOverpaymentsPage.payByChequeAccordion}
        label={payByCheque.label}
        variation="inpage1"
      >
        <Paragraph>{payByCheque.firstParagraph}</Paragraph>
        <Paragraph>{payByCheque.secondParagraph}</Paragraph>
        <Paragraph>
          <Strong>{payByCheque.postToBoldText}</Strong>
        </Paragraph>

        <Paragraph marginBottom="none">
          {payByCheque.address.businessName}
        </Paragraph>
        <Paragraph marginBottom="none" marginTop="none">
          {payByCheque.address.poBox}
        </Paragraph>
        <Paragraph marginBottom="none" marginTop="none">
          {payByCheque.address.city}
        </Paragraph>
        <Paragraph marginTop="none">{payByCheque.address.postcode}</Paragraph>

        <Paragraph>{payByCheque.thirdParagraph}</Paragraph>
      </Accordion>

      <Accordion
        data-testid={
          testIds.makingOverpaymentsPage.internationalPaymentsAccordion
        }
        label={internationalPayments.label}
        variation="inpage1"
      >
        <Paragraph>
          <Strong>{internationalPayments.ibanLabel}</Strong>
          <Text>{internationalPayments.ibanNumber}</Text>
        </Paragraph>

        <Paragraph>{internationalPayments.firstParagraph}</Paragraph>
        <Paragraph>{internationalPayments.secondParagraph}</Paragraph>
      </Accordion>
    </Main>
  );
}
