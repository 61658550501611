import React from 'react';

import { Box, Heading, Paragraph, Button, Link } from '@constellation/core';
import type HeadingProps from '@constellation/core/dist/types/components/DisplayText/displayText.types';

type Props = {
  heading: string;
  headingLevel: HeadingProps['as'];
  paragraph: string;
  buttonLabel: string;
  buttonUrl: string;
  testId: string;
};

export default function InfoWithButton({
  heading,
  headingLevel,
  paragraph,
  buttonLabel,
  buttonUrl,
  testId,
}: Props) {
  return (
    <Box data-testid={testId}>
      <Heading as={headingLevel} size="s4">
        {heading}
      </Heading>

      <Paragraph>{paragraph}</Paragraph>

      <Link as={Button} external href={buttonUrl}>
        {buttonLabel}
      </Link>
    </Box>
  );
}
