import React from 'react';

import { Heading } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';

const bankOfEnglandBaseRate: AppContent['bankOfEnglandBaseRate'] = {
  pageHeading: 'The Bank of England base rate and your mortgage',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Your mortgage repayments',
        sectionBody: [
          {
            text: 'Our tracker rates are linked to the BoE base rate and can go up or down, which will impact the cost of your mortgage payments.',
          },
          {
            text: 'If you have a standard variable rate mortgage, your payments are likely to be affected by a change to the BoE base rate. Any changes we make to our standard variable rate might not change in line with or at the same time as a change to the BoE base rate, as other things can also influence our rates.',
          },
          {
            text: (
              <StyledLink to={routes.RateChangeCalculator}>
                Try our Rate Change Calculator
              </StyledLink>
            ),
          },
        ],
      },
      {
        sectionHeading: 'What happens when the BoE base rate changes?',
        sectionBody: [
          {
            text: "We'll always write to tell you about any changes to your interest rate and monthly payments. Any changes to the interest rate on tracker rate mortgages will start from 01 of the following month.",
          },
        ],
      },
      {
        sectionHeading: "If your mortgage account number starts with '60/'",
        sectionBody: [
          {
            text: "If you're affected by an interest rate change, we'll write to you before the new rate starts.",
          },
          {
            text: "Interest rate changes usually start within 35 days of the BoE base rate change. We'll always give you at least four working days' advance notice before your new payment is due following a BoE base rate change.",
          },
        ],
      },
      {
        sectionHeading:
          "I've just arranged to take out additional borrowing - will the rate change affect me?",
        sectionBody: [
          {
            text: "Any additional borrowing you've arranged on a fixed interest rate will stay the same if the variable mortgage rate changes.",
          },
          {
            text: "If you've arranged additional borrowing at a variable rate, this will show the interest rates that applied at the time. If we change the variable rate after the offer is issued, we won't write to you until you complete. When you complete, we'll write to you telling you your new monthly payment and interest rates.",
          },
        ],
      },
      {
        sectionHeading:
          "How will the rate change affect me if I've arranged to switch to a new fixed rate deal?",
        sectionBody: [
          {
            text: "Any deal you've already arranged will be charged at the agreed interest rate. If any of your mortgage is charged at a variable rate, we'll recalculate your monthly payments and write to you with a full breakdown.",
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading:
          'If interest rates go down',
        sectionBody: [
          {
            text: <Heading>Why is my monthly payment going up when the interest rate is coming down?</Heading>
          },
          {
            text: "This might happen if you owe more than you did when we last recalculated your monthly payment. Or your balance hasn't reduced by as much as it should have since we last recalculated your monthly payment.",
          },
          {
            text: "You might also have arranged to underpay your mortgage, or we might have added a cost or charge to your mortgage, and you haven't arranged to pay it off yet.",
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading:
          'If interest rates go up',
        sectionBody: [
          {
            text: <Heading>Why is my monthly payment coming down when the interest rate is going up?</Heading>
          },
          {
            text: "If you've made any overpayments since we last recalculated your monthly payment, they'll be considered the next time your monthly payment is recalculated. This means your balance will have been reduced by the overpayment amount, and your new monthly payment could be lower even though the interest rate is going up.",
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading:
          'What if part, or all, of my mortgage is on a fixed rate?',
        sectionBody: [
          {
            text: "If your mortgage is on a fixed rate, the interest rate on the fixed part won't change, which means your monthly payments won't either. When the fixed rate comes to an end, we'll calculate a new monthly payment based on the current variable interest rate.",
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading:
          "Can I cancel a switch to a new fixed rate deal I've already agreed?",
        sectionBody: [
          {
            text: "Yes. You have 14 days from your new rate starting to contact us and let us know you've changed your mind.",
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading:
          "What should I do if I'm having difficulty paying my mortgage?",
        sectionBody: [
          {
            text: (
              <>
                If you're finding it difficult to pay your mortgage, please get
                in touch. The sooner you let us know, the sooner{' '}
                <StyledLink to={routes.FinancialDifficulties}>we can help</StyledLink>.
              </>
            ),
          },
        ],
      },
    ],
  },
};

export default bankOfEnglandBaseRate;
