interface CalculateMonthlyPaymentChangeParams {
  mortgageBalanceField: string;
  termLengthYearsField: number;
  termLengthMonthsField: number;
  interestRateField: number;
  repaymentType: string;
  interestRateChange?: number;
}

export const calculateMonthlyPaymentChange = (
  values: CalculateMonthlyPaymentChangeParams,
) => {
  const {
    termLengthYearsField,
    termLengthMonthsField,
    interestRateField,
    repaymentType,
    interestRateChange,
  } = values;

  const mortgageBalanceField = Number(
    parseFloat(values.mortgageBalanceField.replace(/[^0-9.-]+/g, '')),
  );
  const currentAndNewInterestRate =
    Number(parseFloat(interestRateField.toString())) +
    Number(interestRateChange !== undefined ? interestRateChange : 0);
  let monthlyPaymentChange;

  const calculateValues = (interestRate: number) => {
    const lengthOfLoan =
      Number(termLengthYearsField) * 12 + Number(termLengthMonthsField);

    if (interestRate === 0) {
      return mortgageBalanceField / lengthOfLoan;
    }
    const calculatedInterest = interestRate / 100 / 12;
    const exponentiationOperator = (calculatedInterest + 1) ** lengthOfLoan;
    const firstDividend = calculatedInterest * exponentiationOperator;
    const secondDividend = exponentiationOperator - 1;

    return (firstDividend / secondDividend) * mortgageBalanceField;
  };

  if (repaymentType === 'repayment') {
    const currentPaymentRepayment = calculateValues(interestRateField);
    let newPaymentRepayment;

    if (interestRateChange !== undefined || 0) {
      newPaymentRepayment = calculateValues(currentAndNewInterestRate);
      const newMonthlyPaymentRepayment =
        newPaymentRepayment - currentPaymentRepayment;

      monthlyPaymentChange = newMonthlyPaymentRepayment;
    } else {
      monthlyPaymentChange = currentPaymentRepayment;
    }
  } else if (repaymentType === 'interest-only') {
    const currentPaymentInterestOnly =
      (mortgageBalanceField * (interestRateField * 0.01)) / 12;

    if (interestRateChange !== undefined || 0) {
      const newPaymentInterestOnly =
        (mortgageBalanceField * currentAndNewInterestRate) / 100 / 12;
      const newMonthlyPaymentInterestOnly =
        newPaymentInterestOnly - currentPaymentInterestOnly;
      monthlyPaymentChange = newMonthlyPaymentInterestOnly;
    } else {
      monthlyPaymentChange = currentPaymentInterestOnly;
    }
  }
  return Math.abs(Math.ceil(monthlyPaymentChange));
};
