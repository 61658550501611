import React from 'react';

import { Link, Strong } from '@constellation/core';
import { AppContent } from 'content/AppContent';

const lending: AppContent['lending'] = {
  pageHeading: 'Lending',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: `What do APR and APRC mean?`,
        sectionBody: [
          {
            text: `APR stands for Annual Percentage Rate. APRC stands for Annual Percentage Rate of Charge and is an annual interest
             rate which takes account of fees and charges to reflect the total cost of your mortgage. Both the APR and APRC are 
             calculated using a standard method, so you can compare quotes from different lenders.`,
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading: `How do I make an annual repayment change scheme removal request?`,
        sectionBody: [
          {
            text: `Under this scheme, mortgage payments are only calculated once at the start of each mortgage year. This payment 
            remains the same for the following 12 months regardless of any interest rate changes. But you can opt out and have a 
            new payment calculated each time the interest rate changes.`,
          },
          {
            text: (
              <>
                Please call us on <Strong>0345 300 2627</Strong> if you'd like
                to discuss this with us. Our lines are open Monday to Friday 8am
                -8pm and Saturday 9am - 1pm.
              </>
            ),
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading: `How do I change the rate on my mortgage, and/or apply for additional borrowing?`,
        sectionBody: [
          {
            text: `You'll need to speak to a mortgage broker to find your next deal or apply for additional borrowing`,
          },
          {
            boldText: `Not got a mortgage broker?`,
          },
          {
            text: (
              <Link href="https://www.unbiased.co.uk/" external>
                Search for one here
              </Link>
            ),
          },
        ],
      },
    ],
  },
};

export default lending;
