import { Box, GridItem } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const GridItemStretchChildren: StyledComponent<typeof GridItem, any> =
  styled<typeof GridItem>(GridItem)`
    display: flex;
    align-items: stretch;
  `;

export const FullWidthBox: StyledComponent<typeof Box, any> = styled<
  typeof Box
>(Box)`
  width: 100%;
`;
