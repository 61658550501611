import type HeadingProps from '@constellation/core/dist/types/components/DisplayText/displayText.types';
import styled from "styled-components";

export const FlexContainer = styled.div<{ justifyContent: string, flexDirection: string, marginTop: HeadingProps["marginTop"], marginBottom: HeadingProps["marginBottom"] }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  flex-direction: ${props => props.flexDirection};
  margin-top: ${({ theme, marginTop }) => theme[`spacing_${marginTop}`]};
  margin-bottom: ${({ theme, marginBottom }) => theme[`spacing_${marginBottom}`]};
`

export const FlexImg = styled.img<{ maxWidth: string }>`
  max-width: ${props => props.maxWidth};
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

export const FlexTextBox = styled.div<{ marginTop: HeadingProps["marginTop"], marginLeft: HeadingProps["marginLeft"], maxWidth: string }>`

  margin-top: ${({ theme, marginTop }) => theme[`spacing_${marginTop}`]};
  margin-left: ${({ theme, marginLeft }) => theme[`spacing_${marginLeft}`]};
  max-width: ${props => props.maxWidth};
`