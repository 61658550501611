import React from 'react';

import {
  Main,
  Heading,
  Table,
  TableRow,
  TableCol,
  Text,
  Strong,
  ListItem,
  List,
  Paragraph,
  Link,
  ContentGroup,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledList } from 'client/components/sectionBody/SectionBody.styled';
import { ItalicText } from 'client/routes/privacy/components/PrivacyPage.Styled';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { PrivacyPageContent } from './PrivacyPage.config';
import { StyledTableCol, StyledTable } from './PrivacyPage.Styled';

export default function PrivacyPage(): React.JSX.Element {
  const {
    pageHeading,
    firstInfoPanel,
    personalInformationTable,
    processingSpecialCategoriesTable,
    groupsOfPersonalInfo,
    groupsOfPersonalInfoTable,
    secondInfoPanel,
    bankingAndFinancialServicesList,
    insurers,
    otherServicesAndSchemes,
    generalBusiness,
    outsideCompanies,
    companyMergers,
    thirdInfoPanel,
    dataTransferOutsideOfUk,
    fourthInfoPanel,
    personalInformation,
    dataProtectionOfficer,
    unhappyWithDataUsage,
    fifthInfoPanel,
  } = useContent<PrivacyPageContent>();
  return (
    <Main>
      <Helmet>
        <title>Privacy | Birmingham Midshires </title>
      </Helmet>
      <Heading as="h1" size="s7">
        {pageHeading}
      </Heading>
      <InfoPanel
        infoPanel={firstInfoPanel}
        pageTestIds={testIds.privacy.firstInfoPanel}
      />

      <StyledTable>
        <TableRow key="personal-information-table-row">
          {personalInformationTable.headers.map((header) => (
            <StyledTableCol key={header}>
              <Strong>{header}</Strong>
            </StyledTableCol>
          ))}
        </TableRow>
      </StyledTable>

      {personalInformationTable.tableEntries.map((tableEntry) => (
        <StyledTable caption={[<Strong>{tableEntry.heading}</Strong>]}>
          <TableRow key={`personal-infromation-row-${tableEntry}`}>
            <TableCol
              key={`${tableEntry.heading}-reasons-for-using-table-entry`}
            >
              <List>
                {tableEntry.column1.map((entry) => (
                  <ListItem key={`reasons-for-using-list-entry-${entry}`}>
                    {entry}
                  </ListItem>
                ))}
              </List>
            </TableCol>
            <TableCol
              key={`${tableEntry.heading}-what-we-use-information-for-table-entry`}
            >
              <List>
                {tableEntry.column2.map((entry) => (
                  <ListItem
                    key={`what-we-use-information-for-list-entry-${entry}`}
                  >
                    {entry}
                  </ListItem>
                ))}
              </List>
            </TableCol>
            <TableCol
              key={`${tableEntry.heading}-our-legitimate-interests-table-entry`}
            >
              <List>
                {tableEntry.column3.map((entry) => (
                  <ListItem
                    key={`our-legitimate-interests-list-entry-${entry}`}
                  >
                    {entry}
                  </ListItem>
                ))}
              </List>
            </TableCol>
          </TableRow>
        </StyledTable>
      ))}

      <Table
        width="auto"
        responsive
        caption={[<Strong>{processingSpecialCategoriesTable.header}</Strong>]}
        headings={[
          personalInformationTable.headers[0],
          personalInformationTable.headers[1],
        ]}
      >
        {processingSpecialCategoriesTable.column1.map((entry, colIndex) => (
          <TableRow key={`special-categories-row-${entry}`}>
            <TableCol key={`special-categories-header-${entry}`}>
              <Text>{entry}</Text>
            </TableCol>

            <TableCol key={`special-categories-data-${entry}`}>
              <List>
                {processingSpecialCategoriesTable.column2[colIndex].map(
                  (item) => (
                    <ListItem
                      key={`our-legitimate-interests-list-entry-${item}`}
                    >
                      {item}
                    </ListItem>
                  ),
                )}
              </List>
            </TableCol>
          </TableRow>
        ))}
      </Table>

      <Heading
        marginTop="05"
        marginBottom="02"
        as="h2"
        size="s4"
        id={groupsOfPersonalInfo.heading.id}
      >
        {groupsOfPersonalInfo.heading.text}
      </Heading>
      <Strong>{groupsOfPersonalInfo.subheading}</Strong>
      <Paragraph marginTop="02">{groupsOfPersonalInfo.paragraph}</Paragraph>

      <Table headings={groupsOfPersonalInfoTable.headers}>
        {groupsOfPersonalInfoTable.upperColumn1.map((_, entryIndex) => (
          <TableRow
            key={`upper-row-${groupsOfPersonalInfoTable.upperColumn1[entryIndex]}`}
          >
            <TableCol
              key={`upper-personal-info-type-${groupsOfPersonalInfoTable.upperColumn1[entryIndex]}`}
            >
              <Text>{groupsOfPersonalInfoTable.upperColumn1[entryIndex]}</Text>
            </TableCol>

            <TableCol
              key={`upper-personal-info-description-${groupsOfPersonalInfoTable.upperColumn2[entryIndex]}`}
            >
              <Text>{groupsOfPersonalInfoTable.upperColumn2[entryIndex]}</Text>
            </TableCol>
          </TableRow>
        ))}

        <TableRow key="special-categories-row">
          <TableCol key="special-categories-type-row">
            {groupsOfPersonalInfoTable.specialCategoriesType}
          </TableCol>
          <TableCol key="special-cateogries-descrption-row">
            <Paragraph>
              {groupsOfPersonalInfoTable.specialCategoriesFirstLine}
            </Paragraph>
            <List>
              {groupsOfPersonalInfoTable.specialCategoriesList.map(
                (category) => (
                  <ListItem key={`special-category-${category}`}>
                    {category}
                  </ListItem>
                ),
              )}
            </List>
            <Paragraph>
              {groupsOfPersonalInfoTable.specialCategoriesSecondLine}
            </Paragraph>
          </TableCol>
        </TableRow>

        {groupsOfPersonalInfoTable.lowerColumn1.map((_, entryIndex) => (
          <TableRow
            key={`lower-row-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}`}
          >
            <TableCol
              key={`lower-personal-info-type-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}`}
            >
              <Text>{groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}</Text>
            </TableCol>

            <TableCol
              key={`lower-personal-info-description-${groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}`}
            >
              <Text>{groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}</Text>
            </TableCol>
          </TableRow>
        ))}
      </Table>

      <ContentGroup marginTop="05">
        <InfoPanel
          infoPanel={secondInfoPanel}
          pageTestIds={testIds.privacy.secondInfoPanel}
        />
      </ContentGroup>

      <List>
        <ListItem key="agents-suppliers-item">
          <Text>{bankingAndFinancialServicesList.listItems[0]}</Text>
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[0]}
            </ItalicText>
          </Paragraph>
        </ListItem>

        <ListItem key="cra-item">
          {bankingAndFinancialServicesList.listItems[1]}
        </ListItem>

        <ListItem key="linked-person-item">
          <Text>{bankingAndFinancialServicesList.listItems[2]}</Text>
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[1]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="financial-services-company-item">
          {bankingAndFinancialServicesList.listItems[3]}
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[2]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="financial-advisors-item">
          {bankingAndFinancialServicesList.listItems[4]}
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[3]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="comparison-websites-item">
          {bankingAndFinancialServicesList.listItems[5]}
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[4]}
            </ItalicText>
          </Paragraph>
        </ListItem>

        <ListItem key="employers-item">
          {bankingAndFinancialServicesList.listItems[6]}
        </ListItem>

        <ListItem key="companies-item">
          {bankingAndFinancialServicesList.listItems[7]}
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[5]}
            </ItalicText>
          </Paragraph>
        </ListItem>

        <ListItem key="companies-item">
          {bankingAndFinancialServicesList.listItems[8]}
          <Paragraph marginBottom="none">
            <ItalicText>
              {bankingAndFinancialServicesList.italicItems[6]}
            </ItalicText>
          </Paragraph>
        </ListItem>

        <Paragraph>
          <Link external href="https://www.openbanking.org.uk">
            https://www.openbanking.org.uk/
          </Link>
        </Paragraph>
      </List>

      <Heading size="s3">{insurers.heading}</Heading>
      <Paragraph>{insurers.firstParagraph}</Paragraph>
      <StyledList>
        {insurers.listItems.map((listItem) => (
          <ListItem key={`insurer-entry-${listItem}`}>{listItem}</ListItem>
        ))}
      </StyledList>

      <Heading size="s3">{otherServicesAndSchemes.heading}</Heading>
      <Paragraph>{otherServicesAndSchemes.firstParagraph}</Paragraph>
      <List>
        <ListItem key="other-services-item-0">
          <Paragraph marginBottom="none">
            {otherServicesAndSchemes.listItem}
          </Paragraph>
          <ItalicText>{otherServicesAndSchemes.italicListItem}</ItalicText>
        </ListItem>

        {otherServicesAndSchemes.listItems.map((listItem) => (
          <ListItem key={`other-services-item-${listItem}`}>
            {listItem}
          </ListItem>
        ))}
      </List>

      <Heading size="s3">{generalBusiness.heading}</Heading>
      <Paragraph>{generalBusiness.firstParagraph}</Paragraph>
      <List>
        <ListItem key="general-business-item-0">
          {generalBusiness.listItems[0]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[0]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="general-business-item-1">
          {generalBusiness.listItems[1]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[1]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="general-business-item-2">
          {generalBusiness.listItems[2]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[2]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="general-business-item-3">
          {generalBusiness.listItems[3]}
          <Paragraph marginBottom="none">
            <ItalicText>{generalBusiness.italicItems[3]}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="general-business-item-4">
          {generalBusiness.listItems[4]}
          <Paragraph marginBottom="none">
            <Strong>{generalBusiness.soThatHeading}</Strong>
          </Paragraph>
          <StyledList>
            {generalBusiness.soThatList.map((listItem) => (
              <ListItem key={`advertisers-styled-list-${listItem}`}>
                {listItem}
              </ListItem>
            ))}
          </StyledList>
        </ListItem>

        <Link href="#how-we-use-your-information">
          {generalBusiness.learnMoreLink}
        </Link>
      </List>

      <Heading size="s3">{outsideCompanies.heading}</Heading>
      <Paragraph>{outsideCompanies.firstParagraph}</Paragraph>
      <StyledList>
        {outsideCompanies.listItems.map((_, listItemIndex) => (
          <ListItem>
            <Paragraph marginBottom="none">
              {outsideCompanies.listItems[listItemIndex]}
            </Paragraph>
            <Paragraph>
              <ItalicText>
                {outsideCompanies.italicListItems[listItemIndex]}
              </ItalicText>
            </Paragraph>
          </ListItem>
        ))}
      </StyledList>

      <Heading size="s3">{companyMergers.heading}</Heading>
      <Paragraph>{companyMergers.firstParagraph}</Paragraph>
      <List>
        <ListItem key="company-mergers-item-0">
          {companyMergers.listItems[0]}
          <Paragraph marginBottom="none">
            <ItalicText>{companyMergers.italicItem}</ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="company-mergers-item-1">
          {companyMergers.listItems[1]}
        </ListItem>
        <ListItem key="company-mergers-item-2">
          {companyMergers.listItems[2]}
        </ListItem>
      </List>

      <InfoPanel
        infoPanel={thirdInfoPanel}
        pageTestIds={testIds.privacy.thirdInfoPanel}
      />

      <Heading id={dataTransferOutsideOfUk.sectionID}>
        {dataTransferOutsideOfUk.heading}
      </Heading>
      <Strong>{dataTransferOutsideOfUk.subheading}</Strong>
      <Paragraph>{dataTransferOutsideOfUk.firstParagraph}</Paragraph>

      <StyledList>
        <ListItem key="data-transfer-list-item-0">
          {dataTransferOutsideOfUk.dataExceptionListItems[0]}
          <Paragraph marginTop="none" marginBottom="02">
            <ItalicText>
              {dataTransferOutsideOfUk.dataExceptionListExamples[0]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="data-transfer-list-item-1">
          {dataTransferOutsideOfUk.dataExceptionListItems[1]}
          <Paragraph marginTop="none" marginBottom="02">
            <ItalicText>
              {dataTransferOutsideOfUk.dataExceptionListExamples[1]}
            </ItalicText>
          </Paragraph>
        </ListItem>
        <ListItem key="data-transfer-list-item-2">
          {dataTransferOutsideOfUk.dataExceptionListItems[2]}
        </ListItem>
      </StyledList>

      <Paragraph>{dataTransferOutsideOfUk.secondParagraph}</Paragraph>
      <List>
        <ListItem key="data-transfer-safe-guard-list-item-0">
          <Text>
            {dataTransferOutsideOfUk.safeguardListItems[0]}{' '}
            <Link
              external
              href={dataTransferOutsideOfUk.dataProtectionLinkHref}
            >
              {dataTransferOutsideOfUk.dataProtectionLinkLabel}
            </Link>
          </Text>
        </ListItem>
        <ListItem key="data-transfer-safe-guard-list-item-1">
          <Text>
            {dataTransferOutsideOfUk.safeguardListItems[1]}{' '}
            <Link
              external
              href={dataTransferOutsideOfUk.dataProtectionLinkHref}
            >
              {dataTransferOutsideOfUk.dataProtectionLinkLabel}
            </Link>
          </Text>
        </ListItem>
      </List>

      <InfoPanel
        infoPanel={fourthInfoPanel}
        pageTestIds={testIds.privacy.fourthInfoPanel}
      />

      <Heading size="s3" marginBottom="02">
        {personalInformation.heading}
      </Heading>
      <Paragraph marginBottom="02">
        {personalInformation.firstParagraph}
      </Paragraph>
      {personalInformation.mailingAddress.map((addressItem) => (
        <Paragraph marginBottom="none" marginTop="none">
          {addressItem}
        </Paragraph>
      ))}

      <Heading size="s3" marginBottom="02" marginTop="05">
        {dataProtectionOfficer.heading}
      </Heading>
      <Paragraph marginBottom="02">
        {dataProtectionOfficer.firstParagraph}
      </Paragraph>
      {dataProtectionOfficer.mailingAddress.map((addressItem) => (
        <Paragraph marginBottom="none" marginTop="none">
          {addressItem}
        </Paragraph>
      ))}

      <Heading size="s3" marginBottom="02" marginTop="05">
        {unhappyWithDataUsage.heading}
      </Heading>
      <Paragraph marginBottom="02">
        {unhappyWithDataUsage.firstParagraph}
      </Paragraph>
      <Paragraph marginBottom="02">
        {unhappyWithDataUsage.secondParagraph}
      </Paragraph>
      {unhappyWithDataUsage.mailingAddress.map((addressItem) => (
        <Paragraph marginBottom="none" marginTop="none">
          {addressItem}
        </Paragraph>
      ))}

      <ContentGroup marginTop="05" marginBottom="none">
        <InfoPanel
          infoPanel={fifthInfoPanel}
          pageTestIds={testIds.privacy.fifthInfoPanel}
        />
      </ContentGroup>
    </Main>
  );
}
