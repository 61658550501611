const isValidMortgageBalance = (balance: string) => {
  if (balance !== undefined) {
    const formatToNumber = Number(
      parseFloat(balance.replace(/[^0-9.-]+/g, '')),
    );
    if (
      formatToNumber === undefined ||
      formatToNumber < 1 ||
      formatToNumber > 15000000
    ) {
      return false;
    }

    if (isNaN(formatToNumber)) {
      return false;
    }
    return true;
  }
  return false;
};

export default isValidMortgageBalance;
