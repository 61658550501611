import { AppContent } from 'content/AppContent';

const interestAndProducts: AppContent['interestAndProducts'] = {
  pageHeading: 'Interest rates and our products',
  infoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            text: `You can find your interest rates on your mortgage offer and latest yearly statement.`,
          },
          {
            boldText: `If you're unsure about your product or interest rate, please call us on 0345 300 2627. Our lines are open Monday to Friday 8am - 8pm and Saturday 9am - 1pm.`,
          },
        ],
      },
      {
        sectionHeading: `How is my mortgage interest calculated?`,
        sectionBody: [
          {
            text: `Your mortgage is calculated on a daily interest basis. Daily interest is calculated by charging interest on the 
            amount of your outstanding account on a daily basis until you've paid it off.`,
          },
          {
            boldText: `We start charging interest:`,
          },
          {
            list: [
              `on any money we lend you from the day we lend it to you`,
              `on interest from the day after we add it to your mortgage`,
              `from the day we add on any charges and costs to your mortgage.`,
            ],
          },
          {
            text: `Any payment you make will reduce the balance and the amount of interest you're charged from the day we receive it.
             We'll add the interest for each month to what you owe at the end of the last day of that month. We start charging interest 
             on the added interest from the first day of the next month.`,
          },
        ],
      },
      {
        isAccordian: true,
        sectionHeading: `How we work out interest`,
        sectionBody: [
          {
            text: `We take what you owe at the start of every month, then add or take away any charges or monthly payments 
            during that month. We'll calculate your interest for the month as follows:`,
          },
          {
            list: [
              `(what you owe at start of month) x (yearly interest rate) x (days in the month) PLUS`,
              `(any added amount) x (yearly interest rate) x (remaining days in month, including the day of addition) MINUS`,
              `(any payment) x (yearly interest rate) x (remaining days in month, including the day of your payment)`,
            ],
          },
          {
            text: `We divide the total figure by 365 (366 in a leap year). We do our calculation to four decimal places at each step. We round up the result to the nearest penny to give you your interest charge for the month`,
          },
          { boldText: `Here is an example:` },
          {
            text: `Someone owes £100,000 on 01 June. On 16 June, they make a payment of £20,000. Their yearly interest rate is 6%. On that basis, we work out their interest for June as follows.`,
          },
          {
            keyValueList: [
              `£100,000 x 6% x 30 (the number of days in June) = £180,000.0000`,
              `£20,000 x 6% x 15 (the number of days from 16th to 30th June) = £18,000.0000`,
              `£180,000.0000 - £18,000.0000 = £162,000.0000`,
              `£162,000.0000 / 365 = £443.8356`,
            ],
          },
          {text: `Total interest charge for June = £443.84`}
        ],
      },
      {
        sectionHeading: `Certificate of interest requests`,
        sectionBody: [
          {
            text: `If you need this for tax purposes, please call us 0345 300 2627. Our lines are open Monday to Friday 8am - 8pm and Saturday 9am - 1pm.`,
          },
          {
            text: `We can also send you a certificate automatically every year. Please call us on the same number to arrange this.`,
          },
        ],
      },
    ],
  },
};

export default interestAndProducts;
