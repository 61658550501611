import React, { ReactElement } from 'react';

import { Heading, Link, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { Helmet } from 'react-helmet';

import { NotFoundPageContent } from './NotFoundPage.config';
import * as routes from '../manifest';

function NotFoundPage(): ReactElement {
  const { heading, subHeading, footer, footerLink, subFooter } =
    useContent<NotFoundPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Not found | Birmingham Midshires </title>
      </Helmet>
      <Heading as="h4" align="center">
        {heading}
      </Heading>
      <Paragraph align="center">{subHeading}</Paragraph>
      <Paragraph align="center">
        {footer} <Link href={routes.Home}>{footerLink}</Link> {subFooter}
      </Paragraph>
    </Main>
  );
}

export default NotFoundPage;
