import { AppContent } from 'content/AppContent';

const mortgagePaymentCalculator: AppContent['mortgagePaymentCalculator'] = {
  pageHeading: 'Mortgage payment calculator',
  repaymentText: {
    illustrativePurposes: "These figures are for illustrative purposes only."
  },
  mortgageBalanceFieldLabel: 'Mortgage balance',
  termLengthYearsFieldLabel: 'Years',
  termLengthMonthsFieldLabel: 'Months',
  interestRateFieldLabel: 'Interest rate (%)',
  repaymentType: {
    repayment: 'Repayment',
    interestOnly: 'Interest only'
  },
  addAnotherScenarioButton: 'Add another scenario',
  scenarioHeadings: {
    scenarioOne: 'Scenario 1',
    scenarioTwo: 'Scenario 2'
  },
  estimatedMonthlyPayment: 'Estimated monthly payment',
  perMonth: 'per month',
  closeScenario: 'Remove',
  termLengthHeading: 'Term length',
  errors: {
    title: "Something's not quite right",
    mortgageBalance: 'Please enter a value. Min £1 and max £15,000,000.',
    termLengthYears: 'Please enter a value. Min 0 and max 50.',
    termLengthMonths: 'Please enter a value. Min 0 and max 11 months.',
    interestRate: 'Please enter a value. Min 0.1% and max 15%.'
  },
};

export default mortgagePaymentCalculator;
