import React from 'react';

import { Heading, Paragraph, Button } from '@constellation/core';
import heroImage from 'assets/common/homePageHero.jpg';

import { StyledHeroImage, StyledHeroContainer, StyledHeroOverlayText, StyledGradientOverlay } from './HeroComponent.styled';

interface HeroProps {
  caption: string;
  subtext: string;
  btnLabel: string;
  btnHref: string;
  testId: string;
}

function HeroComponent({ caption, subtext, btnLabel, btnHref, testId }: HeroProps): React.JSX.Element {

  return (
    <StyledHeroContainer padding='none'>
      <StyledHeroImage src={heroImage} data-testid={`${testId}-image`} />
      <StyledGradientOverlay />
      <StyledHeroOverlayText>
        <Heading size='s4' marginBottom='none' inverse data-testid={`${testId}-heading`}>{caption}</Heading>
        <Paragraph size='s1' marginTop='01' marginBottom='05' inverse data-testid={`${testId}-subtext`}>{subtext}</Paragraph>
        <div style={{ maxWidth: '125px' }}>
          <Button href={btnHref} data-testid={`${testId}-button`}>{btnLabel}</Button>
        </div>
      </StyledHeroOverlayText>
    </StyledHeroContainer>
  );
}

export default HeroComponent;