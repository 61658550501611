export const ourMortgagesPage = {
  bannerHeading: 'banner-heading',
  subHeading: 'banner-sub-heading',
  firstParagraph: 'first-paragraph',
  secondParagraph: 'second-paragraph',
  mortgageBrokerLinkTitle: 'mortgage-broker-link-title',
  mortgageBrokerLinkText: 'mortgage-broker-link-text',
  homeImage: "home-image",
  ourMortgagesTiles: 'our-mortgages-page-tile-link',
};
