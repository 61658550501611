import React from 'react';

import { Heading, Paragraph, Grid } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { Tile } from './NavigationTiles.config';
import {
  FullWidthBox,
  GridItemStretchChildren,
} from './NavigationTiles.styled';

type Props = {
  navigationTiles: Tile[];
  pageTestId: string;
};

function NavigationTiles({ navigationTiles, pageTestId }: Props) {
  return (
    <Grid>
      {navigationTiles &&
        navigationTiles.map((tile: Tile, index) => (
          <GridItemStretchChildren md={4} key={`${tile.linkText}`}>
            <FullWidthBox
              key={`${tile.linkText}`}
              data-testid={`${pageTestId}-${index + 1}`}
              marginBottom="05"
            >
              <Heading marginBottom="03">
                <StyledLink
                  to={tile.url}
                  iconPosition="right"
                  size="s3"
                  underline
                  weight="bold"
                  data-testid={tile.linkTestId}
                >
                  {tile.linkText}
                </StyledLink>
              </Heading>

              <Paragraph marginBottom="none">{tile.boxText}</Paragraph>
            </FullWidthBox>
          </GridItemStretchChildren>
        ))}
    </Grid>
  );
}

export default NavigationTiles;
