import { List } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledList: StyledComponent<typeof List, any> = styled<
  typeof List
>(List)`
  li {
    margin-bottom: 0rem !important;
  }
`;
