import * as routes from 'client/routes/manifest';

import { AppContent } from '../AppContent';

const ourMortgages: AppContent['ourMortgages'] = {
  pageTitle: 'Our mortgages',
  subHeading:
    'We have a range of buy to let mortgages to suit your needs, whether you have a single property or lots of different properties to rent out.',
  firstParagraph:
    "As a specialist buy to let mortgage provider, our mortgages are available on new properties or if you're wanting to remortgage your existing property. They're suitable for first-time landlords and experienced portfolio landlords. So, whatever you're planning, Birmingham Midshires could have the buy to let mortgage for you.",
  secondParagraph:
    'Our mortgages are only available through a broker, so you’ll need to talk to yours about us and they’ll look after everything for you.',

  linkTitle: 'Not got a mortgage broker?',
  linkText: 'Search for one here',
  url: 'https://www.unbiased.co.uk/',

  navigationTiles: [
    {
      linkText: 'Eligibility',
      boxText:
        'Want to apply for one of our buy to let mortgages? Check if you meet our lending criteria beforehand.',
      url: routes.Eligibility,
    },
    {
      linkText: 'Portfolio landlord mortgages',
      boxText:
        'If you’ve got buy to let mortgages on four or more UK properties, this is the place for you. ',
      url: routes.PortfolioLandlord,
    },
  ],
};

export default ourMortgages;
