import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

interface ScreenContextType { isMobile: boolean; }

const ScreenContext = createContext<ScreenContextType>({ isMobile: false });

export const ScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 750);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 750);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenContext.Provider
      value={{ isMobile }}>
      {children}
    </ScreenContext.Provider>
  );
}
export const useScreen = () => useContext(ScreenContext)