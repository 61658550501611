import React from 'react';

import { Strong } from '@constellation/core';

import { AppContent } from '../AppContent';

const feesAndCharges: AppContent['feesAndCharges'] = {
  pageHeading: 'Fees and charges',
  infoPanel: {
    contentSections: [
      {
        sectionBody: [
          {
            text: 'Some of our extra services have added fees or charges. You can find out what these are in this section.',
          },
        ],
      },
      {
        sectionHeading: `Mortgage account fee`,
        sectionBody: [
          {
            text: "This is an interest-free fee that was charged on new mortgage completions. It'll show as 'sub-account 98' or 'sub-account 99' on your mortgage.",
          },
        ],
      },
      {
        sectionHeading: `Early repayment charges`,
        sectionBody: [
          {
            text: 'Some mortgages have charges for repaying all or part of the mortgage back within a certain time. We call these early repayment charges (ERCs).',
          },
          {
            text: (
              <>
                You can check your mortgage offer or yearly statement to see if
                any ERCs apply to you. Or you can call us on{' '}
                <Strong>0345 300 2627</Strong>.
              </>
            ),
          },
          {
            text: 'Our lines are open Monday to Friday 8am - 8pm and Saturday 9am - 1pm.',
          },
          {
            text: "If ERCs apply, they'll be based on the amount you owe when you make an overpayment to your mortgage. We'll charge you a proportion of the ERC due if you only repay part of your mortgage.",
          },
          {
            text: "We'll waive any ERCs if you have less than three months left on your current mortgage deal with us and you're looking to switch to a new deal. Or if you're moving home and want to take your existing Birmingham Midshires mortgage deal with you.",
          },
          {
            text: 'Where ERCs apply, you can make overpayments of up to 10% of your outstanding balance every calendar year. This starts on 01 January every year. We sometimes refer to this as a concession, meaning we can change the overpayment allowance at any time.',
          },
          {
            text: (
              <>
                You can call us to find out your remaining overpayment allowance
                or to check if the policy has changed on{' '}
                <Strong>0345 300 2627</Strong>. Our lines are open Monday to
                Friday 8am - 8pm and Saturday 9am - 1pm.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: `Valuation and revaluation fees`,
        isAccordian: true,
        sectionBody: [
          {
            text: "A valuation or revaluation is usually needed when you take out a new mortgage or additional borrowing with us. The cost for these is £100 in most cases, but we'll always confirm it in writing before any valuation or revaluation happens.",
          },
        ],
      },
      {
        sectionHeading: `Property re-inspection fees`,
        isAccordian: true,
        sectionBody: [
          {
            text: 'You might be charged a Release of retentions and/or Self-build stage release inspection fee, or a Release of land fee. They both cover the cost of a property inspection and valuation.',
          },
        ],
      },

      {
        sectionHeading: 'Product transfer fee',
        isAccordian: true,
        sectionBody: [
          {
            text: "Changing your mortgage product or switching your deal might mean you'll have to pay a fee.",
          },
        ],
      },
      //
      {
        sectionHeading: 'Vacating fee',
        isAccordian: true,
        sectionBody: [
          {
            text: "This is a fee for repaying your mortgage early or for moving your mortgage to another lender. This will be on your mortgage offer or annual statement if it applies to your mortgage. You won't have to pay this if your mortgage runs its full term.",
          },
        ],
      },
      {
        sectionHeading: 'Collections and recoveries fees',
        isAccordian: true,
        sectionBody: [
          {
            text: 'A charge of £15 is added to your mortgage account if we have to send you a statement of arrears. There is no charge for sending your usual monthly mortgage statements.',
          },
        ],
      },
      {
        sectionHeading: 'Instructing solicitors',
        isAccordian: true,
        sectionBody: [
          {
            text: 'A charge of £100 is added to your mortgage account if we have to instruct solicitors to start legal action against you.',
          },
        ],
      },
      {
        sectionHeading: 'Banking fees',
        isAccordian: true,
        sectionBody: [
          {
            text: 'A charge of £35 is added to your mortgage account if any Direct Debit or cheque payments are returned unpaid.',
          },
        ],
      },
      {
        sectionHeading: 'Other expenses',
        isAccordian: true,
        sectionBody: [
          {
            text: "You'll have to pay any costs we make to third parties instructed to recover any money owed to us, or to create or protect our security, or in using our legal rights.",
          },
          {
            text: "You'll also need to reimburse us for any solicitors' charges we might need to pay.",
          },
          {
            text: 'A copy of this information will be sent to you with your yearly mortgage statement.',
          },
        ],
      },
    ],
  },
};

export default feesAndCharges;
