import React from 'react';

import { Link, Strong } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import * as routes from '../../routes/manifest';
import { AppContent } from '../AppContent';

const manageYourMortgage: AppContent['manageYourMortgage'] = {
  manageYourMortgage: {
    heading: 'Manage your mortgage',
    headingIntroText:
      'Already  got a mortgage with us? Find out how to manage it online, borrow more, switch  your deal, and everything else you need to know.',
    headingIntroText2:
      'Simply select the section you want to know more about on this page to get started.',
  },
  manageYourMortgageOnline: {
    heading: 'Manage your mortgage online',
    heading2IntroText:
      "One of the easiest ways to manage your mortgage is online. You'll need your 14-digit mortgage account number, date of birth, and postcode of the address where you have your mortgage to sign in.",
    signInBtnText: 'Sign in',
    signInBtnHref: 'https://online.bmmortgages.co.uk/dc/sign-in',
    borrowingMore: {
      title: 'Borrowing more?',
      paragraph: 'You could borrow more on your Birmingham Midshires mortgage.',
    },
    readyToBorrowMore: {
      title: 'Ready to borrow more?',
      text: "You'll need to speak to a mortgage broker if you want to borrow more on your mortgage.",
      secondaryTitle: 'Not got a mortgage broker?',
      link: {
        title: 'Search for one here',
        url: 'https://www.unbiased.co.uk/',
      },
    },
    timeToSwitchYourDeal: {
      title: 'Time to switch your mortgage deal?',
      text: "You'll need to speak to a mortgage broker if you want to switch your deal.",
      text2: 'You can do this up to four months before your current deal ends.',
      secondaryTitle: 'Not got a mortgage broker?',
      link: {
        title: 'Search for one here',
        url: 'https://www.unbiased.co.uk/',
      },
    },
  },
  changingMortgageTerm: {
    title: 'Change your mortgage term',
    introText:
      'Extending your mortgage term to reduce your monthly payment amount could be a useful  way of budgeting for the long-term.',
    listItemTitle: 'This might be a good option if you:',
    listItems: [
      "don't have  an interest-only mortgage",
      'are up to date with your payments',
      'are looking to reduce your monthly payments for a longer period of time',
      "understand you'll be paying your mortgage back later in life.",
    ],
    text2: (
      <>
        If you'd like to talk to us about this, please call{' '}
        <Link href="tel:0345 300 2627">0345 300 2627</Link>.
      </>
    ),
    text3:
      "You can also call us on the same number if you'd like to talk to us about reducing your mortgage term. Lines are open Monday to Friday 8am-8pm and Saturdays 9am-1pm. We're not open on Sundays or bank holidays.",
  },
  makingPaymentsOrOverpaying: {
    title: 'Making payments to or overpaying your mortgage',
    text: (
      <>
        You'll find everything to do with making payments and overpayments to
        your mortgage in our{' '}
        <StyledLink to={routes.KnowledgeBase}>
          Knowledge base section
        </StyledLink>
        .
      </>
    ),
  },
  requestingCopyDocuments: {
    title: 'Requesting copy documents',
    text1:
      "We'll automatically post you a mortgage statement each year on the anniversary of when you took out your mortgage.",
    text2:
      "If you'd like a copy statement, you can request one by post or over the phone.",
    text3:
      "You can also request an interim statement which covers the last 12 months' transactions from today's date.",
    boldText: 'Write to:',
    addressLine1: 'Birmingham Midshires Mortgage Servicing',
    addressLine2: 'PO Box 833',
    addressLine3: 'Leeds',
    addressLine4: 'LS1 9PU',
    text4: (
      <>
        <Strong>Call:</Strong>{' '}
        <Link href="tel: 0345 300 2627">0345 300 2627</Link>. Our lines are open
        Monday to Friday 8am - 8pm and Saturday 9am - 1pm. We're not open
        Sundays or bank holidays.
      </>
    ),
  },
  changeAnyDetails: {
    title: 'Need to change any of your details?',
    boldIntroText:
      'If you need to tell us about a name or address change, please write to:',
    addressLine1: 'Birmingham Midshires Mortgage Servicing',
    addressLine2: 'PO Box 833',
    addressLine3: 'Leeds',
    addressLine4: 'LS1 9PU',
    boldText2: "If you've changed your name:",
    listItems: [
      'Please also include a copy of your marriage certificate or deed poll record.',
      'These documents must be certified by a solicitor.',
      "Don't send us originals as we won't return them to you.",
    ],
    boldText3: "If you've changed your address:",
    listItems2: [
      'Please  let us know as soon as possible.',
      'Make sure everyone named on the mortgage signs the letter.',
    ],
    text: "We'll update your details as soon as we receive your letter.",
    text2: (
      <>
        If you've changed your phone number, you can change this online. Or you
        can do it by letter or by calling us on{' '}
        <Link href="tel: 0345 300 2627">0345 300 2627</Link>. Our lines are open
        Monday to Friday 8am - 8pm and Saturday 9am - 1pm.
      </>
    ),
  },
  residentialMortgages: {
    title: 'Residential mortgages',
    text: "We don't offer new residential mortgages to customers.",
    secondTitle:
      'If you already have a Birmingham Midshires residential mortgage',
    text2: (
      <>
        To talk to us about your mortgage, please call{' '}
        <Link href="tel:0345 300 2627">0345 300 2627</Link>.
      </>
    ),
    thirdTitle:
      'If you want to apply for additional borrowing or get a new deal on your Birmingham Midshires residential mortgage',
    text3: (
      <>
        You'll need to{' '}
        <Link
          external
          href="https://www.bankofscotland.co.uk/mortgages/existing-customers/bos-mortgages-existing-customers/new-mortgage-deal.html"
        >
          switch to a new deal with Bank of Scotland
        </Link>
        .
      </>
    ),
    text4: (
      <>
        You can also call us on{' '}
        <Link href="tel:0345 725 3253">0345 725 3253</Link>. Lines are open from
        9am to 5pm Monday to Friday. We're not open on Sundays or bank holidays.
      </>
    ),
  },
};

export default manageYourMortgage;
