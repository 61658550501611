export const Home = '/';
export const MortgageCalculators = '/mortgage-calculators';
export const RateChangeCalculator =
  '/mortgage-calculators/rate-change-calculator';
export const MortgagePaymentCalculator =
  '/mortgage-calculators/mortgage-payment-calculator';
export const Payments = '/payments';
export const KnowledgeBase = '/knowledge-base';
export const HowToMakeAPayment = '/how-to-make-a-payment';
export const makingOverpayments = `/making-overpayments`;
export const ContactUs = '/contact-us';
export const InterestAndProducts = '/interest-and-products';
export const Eligibility = '/eligibility';
export const FinancialDifficulties = '/financial-difficulties';
export const lending = '/lending';
export const ManageYourMortgage = '/manage-your-mortgage';
export const Sustainability = '/sustainability';
export const PortfolioLandlord = '/portfolio-landlord';
export const FeesAndCharges = '/fees-and-charges';
export const BankOfEnglandBaseRate = '/bank-of-england-base-rate';
export const OurMortgages = '/our-mortgages';
export const Complaints = '/complaints';
export const PropertyDeeds = '/your-property-deeds';
export const CookiesPolicy = '/cookies-policy';
export const PrivacyPolicy = '/privacy-policy';
export const RedirectToPrivacyPolicy =
  '/existing-customers/security-and-privacy';
export const TermsAndConditions = '/existing-customers/terms';
export const Accessibility = '/accessibility';
export const SiteMap = '/site-map';