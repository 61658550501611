import React from 'react';

import { Button, Heading, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { ContactUsPageContent } from './ContactUsPage.config';

export default function ContactUsPage() {
  const { pageHeading, existingMortgageSection, infoPanel } =
    useContent<ContactUsPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Contact us | Birmingham Midshires </title>
      </Helmet>
      <Heading as="h1" size="s7">
        {pageHeading}
      </Heading>

      <Heading as="h3" size="s4">
        {existingMortgageSection.existingMortgageHeader}
      </Heading>

      <Paragraph>{existingMortgageSection.existingMortgageText}</Paragraph>

      <Button href={existingMortgageSection.existingMortgageButton.url}>
        {existingMortgageSection.existingMortgageButton.label}
      </Button>

      <Paragraph marginTop="05">
        {existingMortgageSection.existingMortgageCallUs}
      </Paragraph>

      <Paragraph>
        {existingMortgageSection.existingMortgagesAlternative}
      </Paragraph>
      <Paragraph>{existingMortgageSection.existingMortgagesWrite}</Paragraph>
      <Paragraph marginBottom="none">
        {existingMortgageSection.existingMortgagesWriteAddressLine1}
      </Paragraph>
      <Paragraph marginBottom="none">
        {existingMortgageSection.existingMortgagesWriteAddressLine2}
      </Paragraph>
      <Paragraph>
        {existingMortgageSection.existingMortgagesWriteAddressLine3}
      </Paragraph>

      <InfoPanel infoPanel={infoPanel} pageTestIds={testIds.contactUs} />
    </Main>
  );
}
