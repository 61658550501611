import React from 'react';

import {
  ContentGroup,
  Heading,
  Grid,
  GridItem,
  Paragraph,
  Link,
  Hr,
  List,
  ListItem,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import manageYourMortgageDoor from 'assets/common/borrow-more.jpg';
import manageYourMortgageScreen from 'assets/common/manageYourMortgageScreen.png';
import timeToSwitchImage from 'assets/common/switch-deal.jpg';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import { Helmet } from 'react-helmet';

import { ManageYourMortgageContent } from './ManageYourMortgagePage.config';
import {
  StyledManageYourMortgageOnline,
  StyledButton,
} from './ManageYourMortgagePage.styled';

export default function ManageYourMortgagePage() {
  const {
    manageYourMortgage,
    manageYourMortgageOnline,
    changingMortgageTerm,
    makingPaymentsOrOverpaying,
    requestingCopyDocuments,
    changeAnyDetails,
    residentialMortgages,
  } = useContent<ManageYourMortgageContent>();

  return (
    <Main>
      <Helmet>
        <title>Manage your mortgage | Birmingham Midshires </title>
      </Helmet>
      <GradientBox>
        <ContentBox
          imgSrc={manageYourMortgageScreen}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="05"
          marginTop="05"
        >
          <Heading inverse size="s7" marginBottom="05" as="h1">
            {manageYourMortgage.heading}
          </Heading>
          <Paragraph inverse weight="bold" size="s2">
            {manageYourMortgage.headingIntroText}
          </Paragraph>
          <Paragraph inverse marginBottom="08">
            {manageYourMortgage.headingIntroText2}
          </Paragraph>
        </ContentBox>
      </GradientBox>

      <ContentGroup>
        <Grid>
          <GridItem lg={8} md={8}>
            <Heading marginTop="02" size="s5" as="h2">
              {manageYourMortgageOnline.heading}
            </Heading>
            <Paragraph size="s2">
              {manageYourMortgageOnline.heading2IntroText}
            </Paragraph>
            <StyledButton
              width="content"
              href={manageYourMortgageOnline.signInBtnHref}
            >
              {manageYourMortgageOnline.signInBtnText}
            </StyledButton>
          </GridItem>
        </Grid>

        <Grid>
          <GridItem lg={4} md={4} sm={12}>
            <StyledManageYourMortgageOnline src={manageYourMortgageDoor} />
            <Heading size="s2" marginBottom="02" as="h3">
              {manageYourMortgageOnline.readyToBorrowMore.title}
            </Heading>

            <Paragraph size="s1">
              {manageYourMortgageOnline.borrowingMore.paragraph}
            </Paragraph>
            <Paragraph size="s1">
              {manageYourMortgageOnline.readyToBorrowMore.text}
            </Paragraph>
            <Paragraph size="s1" weight="bold" marginBottom="none">
              {manageYourMortgageOnline.readyToBorrowMore.secondaryTitle}
            </Paragraph>
            <Link
              external
              href={manageYourMortgageOnline.readyToBorrowMore.link.url}
              size="s1"
            >
              {manageYourMortgageOnline.readyToBorrowMore.link.title}
            </Link>
          </GridItem>

          <GridItem lg={4} md={4} sm={12}>
            <StyledManageYourMortgageOnline src={timeToSwitchImage} />
            <Heading size="s2" as="h3" marginBottom="02">
              {manageYourMortgageOnline.timeToSwitchYourDeal.title}
            </Heading>
            <Paragraph size="s1">
              {manageYourMortgageOnline.timeToSwitchYourDeal.text}
            </Paragraph>
            <Paragraph size="s1">
              {manageYourMortgageOnline.timeToSwitchYourDeal.text2}
            </Paragraph>
            <Paragraph size="s1" weight="bold" marginBottom="none">
              {manageYourMortgageOnline.timeToSwitchYourDeal.secondaryTitle}
            </Paragraph>
            <Link
              external
              href={manageYourMortgageOnline.timeToSwitchYourDeal.link.url}
              size="s1"
            >
              {manageYourMortgageOnline.timeToSwitchYourDeal.link.title}
            </Link>
          </GridItem>
        </Grid>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2">
          {changingMortgageTerm.title}
        </Heading>
        <Paragraph size="s2">{changingMortgageTerm.introText}</Paragraph>
        <Paragraph weight="bold" size="s2">
          {changingMortgageTerm.listItemTitle}
        </Paragraph>
        <List data-testid="change-mortgage-term-list">
          {changingMortgageTerm.listItems.map((item: string, index) => (
            <ListItem
              data-testid={`change-mortgage-term-list-item-${index}`}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>
        <Paragraph size="s2">{changingMortgageTerm.text2}</Paragraph>
        <Paragraph size="s2">{changingMortgageTerm.text3}</Paragraph>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2">
          {makingPaymentsOrOverpaying.title}
        </Heading>
        <Paragraph size="s2">{makingPaymentsOrOverpaying.text}</Paragraph>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2">
          {requestingCopyDocuments.title}
        </Heading>
        <Paragraph size="s2">{requestingCopyDocuments.text1}</Paragraph>
        <Paragraph size="s2">{requestingCopyDocuments.text2}</Paragraph>
        <Paragraph size="s2">{requestingCopyDocuments.text3}</Paragraph>

        <Paragraph weight="bold" size="s2" marginBottom="none">
          {requestingCopyDocuments.boldText}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.addressLine1}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.addressLine2}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.addressLine3}
        </Paragraph>
        <Paragraph size="s2">{requestingCopyDocuments.addressLine4}</Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {requestingCopyDocuments.text4}
        </Paragraph>

        <Hr />

        <Heading size="s5" marginBottom="05" as="h2">
          {changeAnyDetails.title}
        </Heading>
        <Paragraph size="s2" weight="bold" marginBottom="none">
          {changeAnyDetails.boldIntroText}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.addressLine1}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.addressLine2}
        </Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.addressLine3}
        </Paragraph>
        <Paragraph size="s2">{changeAnyDetails.addressLine4}</Paragraph>

        <Paragraph size="s2" weight="bold" marginBottom="none">
          {changeAnyDetails.boldText2}
        </Paragraph>
        <List data-testid="change-mortgage-term-list">
          {changeAnyDetails.listItems.map((item: string, index) => (
            <ListItem
              data-testid={`change-mortgage-term-list-item-${index}`}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>

        <Paragraph size="s2" weight="bold" marginBottom="none">
          {changeAnyDetails.boldText3}
        </Paragraph>
        <List data-testid="change-mortgage-term-list">
          {changeAnyDetails.listItems2.map((item: string, index) => (
            <ListItem
              data-testid={`change-mortgage-term-list-item-${index}`}
              key={item}
            >
              {item}
            </ListItem>
          ))}
        </List>

        <Paragraph size="s2">{changeAnyDetails.text}</Paragraph>
        <Paragraph size="s2" marginBottom="none">
          {changeAnyDetails.text2}
        </Paragraph>
      </ContentGroup>

      <Hr />

      <Heading size="s5" marginBottom="05" as="h2">
        {residentialMortgages.title}
      </Heading>

      <Paragraph>{residentialMortgages.text}</Paragraph>

      <Paragraph weight="bold" marginBottom="02">
        {residentialMortgages.secondTitle}
      </Paragraph>

      <Paragraph>{residentialMortgages.text2}</Paragraph>

      <Paragraph weight="bold" marginBottom="02">
        {residentialMortgages.thirdTitle}
      </Paragraph>

      <Paragraph>{residentialMortgages.text3}</Paragraph>

      <Paragraph>{residentialMortgages.text4}</Paragraph>
    </Main>
  );
}
