import React from 'react';

import { Link, Text } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

const links = {
  earlyRepaymentCharge: {
    href: routes.FeesAndCharges,
    label: 'early repayment charge',
  },
  moneyWellness: {
    href: 'https://www.moneywellness.com/',
    label: 'Money Wellness',
  },
  stepChange: {
    href: 'https://www.stepchange.org',
    label: 'StepChange',
  },
  complaintsProcedure: {
    href: routes.Complaints,
    label: 'View our full complaints procedure here',
  },
};

const financialDifficulties: AppContent['financialDifficulties'] = {
  pageHeading: 'Financial difficulty',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: `If you're facing financial difficulty, but haven't missed a mortgage payment`,
        sectionBody: [
          {
            text: 'Please let us know as soon as possible, so we can help.',
          },
          {
            text: 'Before you contact us, to save time and give you the best help we can, please have these things to hand:',
          },
          {
            list: [
              'Your account numbers.',
              <Text>
                Your monthly{' '}
                <Link
                  external
                  data-testid="financial-difficulties-income-and-expenditure-link"
                  href="/public/income_and_expenditure_form.pdf"
                >
                  income and expenditure.
                </Link>{' '}
                This information will help us understand the money you’ve got
                coming in and regularly going out on bills and living costs so
                we can provide you with the appropriate support.
              </Text>,
              'And a pen and paper to jot things down.',
            ],
          },
          {
            text: (
              <Text align="justify">
                Call us on <Link href="tel:0808 145 0372">0808 145 0372</Link>.
                Lines are open Monday to Friday 8am-8pm and Saturday 8am-5pm.
                We're not open on Sundays or bank holidays.
              </Text>
            ),
          },
          {
            text: 'The sooner you get in touch, the sooner we can help.',
          },
        ],
      },
      {
        sectionHeading: `If you've already missed a mortgage payment, we'll:`,
        sectionBody: [
          {
            list: [
              'try to contact you as soon as possible to discuss your circumstances',
              'give you a reasonable period of time to get back up to date',
              'complete a financial assessment',
              'try to agree a new payment plan with you',
              'offer to change how you make your payments and/or your payment date',
              'consider a longer mortgage term to reduce your monthly payments',
              'consider changing your mortgage type to suit your change in circumstances.',
            ],
          },
          {
            boldText: 'If we agree a new payment plan with you',
          },
          {
            text: `It's important you keep to it or tell us if there is a change in your circumstances that might mean you can't.`,
          },
          {
            text: `If we can offer one or more option, we'll explain how they work and give you time to consider your next step. If we can't offer you any of the options, we'll tell you why.`,
          },
        ],
      },
      {
        sectionHeading: `If you're in arrears and we have to start legal proceedings`,
        sectionBody: [
          {
            text: `If we can't agree on a way to clear your arrears, we might go to court to start proceedings to repossess your property. This doesn't mean we'll always repossess your property. Repossession is always a last resort, and we'll work with you to try to find a different solution.`,
          },
          {
            boldText: 'If we have to repossess your home:',
          },
          {
            list: [
              `We'll sell it for the best price we can as soon as possible.`,
              `We'll give you reasonable time to remove your possessions.`,
              `We'll use the money raised from selling your home to repay our loans and charges first.`,
              `We'll pay any money to you after any second mortgage charge with another lender on the property has been paid.`,
              `We'll give you advice about getting in touch with your local authority to see if they can find you somewhere to live.`,
              `If there's not enough money from the sale to pay off the entire mortgage, you'll still owe us the left-over amount.`,
              'Everyone named on the mortgage will be responsible for all the money borrowed, even if you normally only pay part of the mortgage.',
              `We'll take account of your income and outgoings when trying to arrange a suitable payment plan for this shortfall debt with you.`,
              `If we can't arrange a plan, we might go to court to recover the money, and you might have to pay additional court costs.`,
              'Not paying a shortfall debt could affect whether you can get credit in future.',
              `Any additional costs incurred, such as legal costs, will be passed on to you. We'll always tell you the amount you'll have to pay.`,
            ],
          },
        ],
      },
      {
        sectionHeading: 'Interest-only mortgages',
        sectionBody: [
          {
            text: 'For interest-only mortgages your monthly payments pays the interest owed for the month only. At the end of your mortgage term, you must pay off everything else you owe on your mortgage.',
          },
          {
            text: `If the balance on your interest-only mortgage increases, we'll add this on an interest-only basis. You'll need to make sure your repayment plan is enough to repay the balance in full when the term ends.`,
          },
          {
            text: (
              <Text>
                If you're worried about paying back the amount you owe, please
                call <Link href="tel:0800 056 2980">0800 056 2980</Link>, so we
                can discuss your options and help you decide what's best.
              </Text>
            ),
          },
          {
            boldText: 'Your options might include:',
          },
          {
            list: [
              'transferring all or part of your mortgage to repayment over a term you can afford,',
              <Text>
                making overpayments to reduce the amount you owe and the
                interest you pay on your mortgage. You might need to pay an{' '}
                <StyledLink to={links.earlyRepaymentCharge.href}>
                  {links.earlyRepaymentCharge.label}
                </StyledLink>{' '}
                (ERC) if you do this,
              </Text>,
              'selling your home.',
            ],
          },
          {
            text: `When you call us about your interest-only mortgage, please make sure you've got your income and expenditure details to hand.`,
          },
        ],
      },
      {
        sectionHeading: 'Getting extra help and advice',
        sectionBody: [
          {
            text: (
              <>
                We'd recommend you get free, independent advice from
                organisations such as{' '}
                <Link href="#step-change-debt-charity">
                  Step Change Debt Charity
                </Link>{' '}
                or <Link href="#money-wellness">Money Wellness</Link>. We can
                talk to these organisations on your behalf if you'd like us to.
                You can also discuss your situation face-to-face with one of our
                agents if you prefer. If you speak to a solicitor or other
                professional, there might be a charge.
              </>
            ),
          },
          {
            text: "It's also worth checking whether you can get any state benefits or tax credits, which could help to increase your income. And if you have income protection insurance or something similar, this could help with your payments.",
          },
          {
            text: (
              <>
                If you need help call us on{' '}
                <Link href="tel:0808 145 0372">0808 145 0372</Link>. Lines are
                open Monday to Friday 8am-8pm and Saturday 8am-5pm. We're not
                open on Sundays or bank holidays.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Step Change Debt Charity',
        sectionID: 'step-change-debt-charity',
        sectionBody: [
          {
            boldText: 'Who are they?',
          },
          {
            text: "StepChange have a team of debt experts that help hundreds of thousands of people a year to deal with their debt problems. With 30 years' experience, you can be confident that they can provide the advice and support you need to achieve long-term financial control.",
          },
          {
            boldText: 'What support they can offer?',
          },
          {
            list: [
              '24/7, free flexible debt help based on your situation to get your finances back on track,',
              "a wide range of practical debt solutions and support for however long it's needed,",
              'access to a benefits calculator and cost-of-living budgeting advice,',
              'homeowner support through specialist mortgage and equity release teams.',
            ],
          },
          {
            boldText: 'How to get in touch',
          },
          {
            text: (
              <>
                Website:{' '}
                <Link external href={links.stepChange.href}>
                  {links.stepChange.label}
                </Link>
              </>
            ),
          },
          {
            text: (
              <>
                If you would like to speak to someone at StepChange, please call{' '}
                <Link href="tel:0800 138 1111">0800 138 1111</Link> (Lines open
                Mon-Fri 8am-8pm, Sat 9am-2pm).
              </>
            ),
          },
          {
            boldText: 'What will you need when you speak to them?',
          },
          {
            list: [
              'your income: any wages, benefits or other forms of income,',
              'your outgoings: how much you spend each month on rent, mortgage, utilities, food, travel, and other essential costs,',
              'any debts you have, such as: Credit Cards, Loans, Car Finance, and any arrears on rent, utilities or Council Tax.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Money Wellness',
        sectionID: 'money-wellness',
        sectionBody: [
          {
            boldText: 'Who are they?',
          },
          {
            text: 'Money Wellness are passionate about improving financial wellbeing, offering free advice and support to over a million people for the last 30 years.',
          },
          {
            boldText: 'What support they can offer?',
          },
          {
            list: [
              'free Debt advice,',
              'free ongoing debt solutions,',
              'free benefit and grant eligibility assessments,',
              'partnered with additional support providers to offer mental health, gambling and addiction support where required.',
            ],
          },
          {
            boldText: 'How to get in touch',
          },
          {
            text: (
              <>
                Website:{' '}
                <Link external href={links.moneyWellness.href}>
                  {links.moneyWellness.label}
                </Link>
              </>
            ),
          },
          {
            text: (
              <>
                If you would like to speak to someone at Money Wellness, please
                call <Link href="tel:0800 307 7002">0800 307 7002</Link> (Lines
                open Mon-Thurs 9am-8pm, Fri 9am-4.30pm, Sat-Sun 10am-4pm).
              </>
            ),
          },
          {
            text: 'They also offer:',
          },
          {
            list: [
              'a callback option and calendar that you can access,',
              'webchat and email communication channels.',
            ],
          },
          {
            text: 'What will you need when you speak to them?',
          },
          {
            list: [
              'your income: any wages, benefits or other forms of income,',
              'your outgoings: how much you spend each month on rent, mortgage, utilities, food, travel, and other essential costs,',
              'any debts you have, such as: Credit Cards, Loans, Car Finance, and any arrears on rent, utilities or Council Tax,',
              'ensure you have at least an hour available for an advice session.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Complaints',
        sectionBody: [
          {
            text: (
              <Text>
                If you think we haven't treated you fairly in dealing with any
                arrears on your mortgage, please call us on{' '}
                <Link href="tel:0808 145 0372">0808 145 0372</Link> to discuss
                it. The chances are your complaint can be resolved quickly.
              </Text>
            ),
          },
          {
            text: (
              <StyledLink to={links.complaintsProcedure.href}>
                {links.complaintsProcedure.label}
              </StyledLink>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Things to be aware of',
        sectionBody: [
          {
            text: 'Some companies might offer new loans or try to get you to sell them your property and lease it back. Please get independent advice before doing this as it might not be the best thing for you in the long-term.',
          },
        ],
      },
    ],
  },
};

export default financialDifficulties;
