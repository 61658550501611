import React from 'react';

import { Heading, Main, Paragraph, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import bookOutline from 'assets/common/book-outline.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import { NavigationTiles } from 'client/components/navigationTiles';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { KnowledgeBasePageContent } from './KnowledgeBasePage.config';

function KnowledgeBasePage(): React.JSX.Element {
  const { pageTitle, bannerSubheader, bannerText, navigationTiles } =
    useContent<KnowledgeBasePageContent>();

  return (
    <Main>
      <Helmet>
        <title>Knowledge Base | Birmingham Midshires </title>
      </Helmet>
      <GradientBox>
        <ContentBox
          imgSrc={bookOutline}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
        >
          <Heading
            as="h1"
            size="s7"
            inverse
            data-testid={testIds.knowledgeBasePage.heading}
          >
            {pageTitle}
          </Heading>
          <Strong inverse>{bannerSubheader}</Strong>
          <Paragraph marginBottom="08" marginTop="05" inverse>
            {bannerText}
          </Paragraph>
        </ContentBox>
      </GradientBox>
      <NavigationTiles
        navigationTiles={navigationTiles}
        pageTestId={testIds.knowledgeBasePage.knowledgeBaseTiles}
      />
    </Main>
  );
}

export default KnowledgeBasePage;
