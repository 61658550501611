export const homePage={
    heroComponent: 'home-hero-component',

    whoWeAreImg: 'who-we-are',
    whoWeAreHeading: 'who-we-are-heading',
    whoWeAreFirstParagraph: 'who-we-are-first-paragraph',
    whoWeAreSecondParagraph: 'who-we-are-second-paragraph',

    whatWeOfferImg: 'what-we-offer',
    whatWeOfferHeading: 'what-we-offer-heading',
    whatWeOfferFirstParagraph: 'what-we-offer-first-paragraph',
    whatWeOfferSecondParagraph: 'what-we-offer-second-paragraph',
    whatWeOfferButton: 'what-we-offer-button',

    existingCustomerHelpHeading: 'existing-customer-help-heading',
    existingCustomerHelpSubheading: 'existing-customer-help-subheading',

    switchYourMortgageImg: 'switch-your-mortgage-image',
    switchYourMortgageHeading: 'switch-your-mortgage-heading',
    switchYourMortgageSubheading: 'switch-your-mortgage-subheading',
    switchYourMortgageButton: 'switch-your-mortgage-button',

    manageYourMortgageImg: 'manage-your-mortgage-image',
    manageYourMortgageHeading: 'manage-your-mortgage-heading',
    manageYourMortgageSubheading: 'manage-your-mortgage-subheading',
    manageYourMortgageButton: 'manage-your-mortgage-button',

    borrowMoreImg: 'borrow-more-image',
    borrowMoreHeading: 'borrow-more-heading',
    borrowMoreSubHeading: 'borrow-more-subheading',
    borrowMoreButton: 'borrow-more-button',

    sustainabilityImage: 'sustainability-image',
    sustainabilityHeading: 'sustainability-heading',
    sustainabilityFirstParagraph: 'sustainability-sub-text',
    sustainabilityButton: 'sustainability-button',
}
