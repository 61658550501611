import { AppContent } from 'content/AppContent';

import footer from './appFooter';
import navigationMenu from './navigationMenu';

export const appLayout: AppContent['appLayout'] = {
  footer,
  navigationMenu,
  header: {
    signInSection: {
      text: 'Sign in',
      href: 'https://online.bmmortgages.co.uk/dc/sign-in'
    },
    accessibleLogoDescription: {
      text: 'Birmginham Midshires Logo',
    },
  },
};
