import React from 'react';

import { Main, Heading, ContentGroup } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { PropertyDeedsPageContent } from './PropertyDeedsPage.config';
import * as routes from '../../manifest';

export default function PropertyDeedsPage() {
  const { pageHeading, infoPanel } = useContent<PropertyDeedsPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Property deeds | Knowledge Base | Birmingham Midshires </title>
      </Helmet>
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.KnowledgeBase}
        >
          Back
        </StyledLink>
        <Heading
          marginTop="05"
          as="h1"
          size="s7"
          data-testid={testIds.propertyDeeds.heading}
        >
          {pageHeading}
        </Heading>
        <InfoPanel infoPanel={infoPanel} pageTestIds={testIds.propertyDeeds} />
      </ContentGroup>
    </Main>
  );
}
