import { TableCol, Table, Text } from "@constellation/core";
import styled, { StyledComponent } from "styled-components";


export const StyledTableCol: StyledComponent<typeof TableCol, any> = styled<typeof TableCol>(TableCol)`
  background-color: #f1f1f1;
`
export const StyledTable: StyledComponent<typeof Table, any> = styled<typeof Table>(Table)`
  table-layout: fixed;
`
export const ItalicText: StyledComponent<typeof Text, any> = styled<typeof Text>(Text)`
&&&{
  font-style: italic;
}
`