import React from 'react';

import { Link, Strong } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';


const contactUs: AppContent['contactUs'] = {
  pageHeading: 'Contact us',
  existingMortgageSection: {
    existingMortgageHeader: `Existing mortgages`,
    existingMortgageText: "You can manage your mortgage online. You'll need your 14-digit mortgage account number, date of birth, and postcode of the address where you have your mortgage to sign in.",
    existingMortgageButton: {
      url: 'https://online.bmmortgages.co.uk/dc/sign-in',
      label: 'Sign in'
    },
    existingMortgageCallUs: (
      <>
        Alternatively, call us on {' '}
        <Link href="tel:0345 300 2627">
          0345 300 2627
        </Link>.
      </>
    ),
    existingMortgagesAlternative: "Lines are open Monday to Friday 8am-8pm and Saturday 9am-1pm. We're closed on Sundays and bank holidays.",
    existingMortgagesWrite: (
      <>
        {' '}
        <Strong>
          You can also write to our mortgage servicing team:{' '}
        </Strong>
      </>
    ),
    existingMortgagesWriteAddressLine1: 'PO Box 833',
    existingMortgagesWriteAddressLine2: 'Leeds',
    existingMortgagesWriteAddressLine3: 'LS1 9PU'
  },
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Your property deeds',
        sectionBody: [
          {
            text: 'Not sure where your property deeds are held or how to get your hands on them?',
          },
          {
            text: (
              <StyledLink to={routes.PropertyDeeds}>
                Find out more here
              </StyledLink>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Want to make a complaint?',
        sectionBody: [
          {
            text: <>
              If you have a complaint about any of our services or products, <StyledLink to={routes.Complaints}>please get in touch</StyledLink>.
            </>
          },
        ],
      },
      {
        sectionHeading: 'Not an existing customer?',
        sectionBody: [
          {
            text: "Our mortgages are only available through a broker, so you'll need to talk to yours to find out more.",
          },
        ],
      },
      {
        sectionHeading: 'Not got a mortgage broker?',
        sectionBody: [
          {
            text:
              <Link external href="https://www.unbiased.co.uk/">
                Search for one here
              </Link>
          },
        ],
      },
      {
        sectionHeading: 'Residential mortgages',
        sectionBody: [
          {
            text: <>To talk to us about your mortgage, please call <Link href='tel:0345 300 2627'>0345 300 2627</Link>.</>
          }
        ]
      },
      {
        sectionHeading: 'Press enquiries',
        sectionBody: [
          {
            text: (
              <>
                Contact our{' '}
                <Link
                  href="https://www.lloydsbankinggroup.com/contact.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1"
                  external
                >
                  Lloyds Banking Group Media Relations Team
                </Link>.
              </>
            ),
          },
        ],
      },
    ],
  },
};

export default contactUs;